export const primeTimeFontData = [
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "3'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 36, "T": 34, "TT": 24, "TB": 26 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "3'", "P": 25, "S": 22, "T": 26, "TT": 13, "TB": 12 },
    { "LETTER": "2", "WIDTH": 2.5, "HEIGHT": "3'", "P": 45, "S": 38, "T": 37, "TT": 21, "TB": 24 },
    { "LETTER": "3", "WIDTH": 2.5, "HEIGHT": "3'", "P": 47, "S": 37, "T": 35, "TT": 22, "TB": 25 },
    { "LETTER": "4", "WIDTH": 3, "HEIGHT": "3'", "P": 49, "S": 34, "T": 34, "TT": 27, "TB": 22 },
    { "LETTER": "5", "WIDTH": 2.5, "HEIGHT": "3'", "P": 47, "S": 39, "T": 36, "TT": 22, "TB": 25 },
    { "LETTER": "6", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 37, "T": 33, "TT": 21, "TB": 29 },
    { "LETTER": "7", "WIDTH": 2.5, "HEIGHT": "3'", "P": 34, "S": 30, "T": 32, "TT": 21, "TB": 13 },
    { "LETTER": "8", "WIDTH": 2.5, "HEIGHT": "3'", "P": 54, "S": 35, "T": 31, "TT": 25, "TB": 29 },
    { "LETTER": "9", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 37, "T": 33, "TT": 25, "TB": 25 },
    { "LETTER": "!", "WIDTH": 1, "HEIGHT": "3'", "P": 20, "S": 23, "T": 24, "TT": 11, "TB": 9 },
    { "LETTER": "\\", "WIDTH": 2.5, "HEIGHT": "3'", "P": 12, "S": 18, "T": 22, "TT": 12, "TB": 0 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "3'", "P": 82, "S": 72, "T": 55, "TT": 39, "TB": 43 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "3'", "P": 55, "S": 36, "T": 32, "TT": 25, "TB": 30 },
    { "LETTER": "%", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 38, "T": 36, "TT": 17, "TB": 18 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 58, "S": 41, "T": 35, "TT": 20, "TB": 38 },
    { "LETTER": "'", "WIDTH": 1, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13, "TT": 5, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "3'", "P": 9, "S": 13, "T": 17, "TT": 9, "TB": 0 },
    { "LETTER": "+", "WIDTH": 2.5, "HEIGHT": "3'", "P": 29, "S": 24, "T": 28, "TT": 12, "TB": 17 },
    { "LETTER": ",", "WIDTH": 1, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13, "TT": 0, "TB": 5 },
    { "LETTER": "-", "WIDTH": 2.5, "HEIGHT": "3'", "P": 17, "S": 16, "T": 20, "TT": 6, "TB": 11 },
    { "LETTER": ".", "WIDTH": 1, "HEIGHT": "3'", "P": 4, "S": 8, "T": 12, "TT": 0, "TB": 4 },
    { "LETTER": "/", "WIDTH": 2.5, "HEIGHT": "3'", "P": 27, "S": 28, "T": 32, "TT": 13, "TB": 14 },
    { "LETTER": ":", "WIDTH": 1, "HEIGHT": "3'", "P": 8, "S": 16, "T": 21, "TT": 4, "TB": 4 },
    { "LETTER": ";", "WIDTH": 1, "HEIGHT": "3'", "P": 9, "S": 17, "T": 22, "TT": 4, "TB": 5 },
    { "LETTER": "=", "WIDTH": 2.5, "HEIGHT": "3'", "P": 34, "S": 25, "T": 24, "TT": 17, "TB": 17 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 29, "S": 32, "T": 30, "TT": 20, "TB": 9 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 75, "S": 56, "T": 38, "TT": 37, "TB": 38 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 64, "T": 64, "TT": 52, "TB": 56 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "4'", "P": 47, "S": 30, "T": 34, "TT": 23, "TB": 24 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "4'", "P": 92, "S": 59, "T": 61, "TT": 40, "TB": 52 },
    { "LETTER": "3", "WIDTH": 3, "HEIGHT": "4'", "P": 106, "S": 74, "T": 71, "TT": 51, "TB": 55 },
    { "LETTER": "4", "WIDTH": 3.5, "HEIGHT": "4'", "P": 87, "S": 54, "T": 50, "TT": 40, "TB": 47 },
    { "LETTER": "5", "WIDTH": 3, "HEIGHT": "4'", "P": 115, "S": 71, "T": 67, "TT": 59, "TB": 56 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "4'", "P": 118, "S": 69, "T": 64, "TT": 57, "TB": 61 },
    { "LETTER": "7", "WIDTH": 3, "HEIGHT": "4'", "P": 86, "S": 50, "T": 53, "TT": 53, "TB": 33 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "4'", "P": 119, "S": 70, "T": 66, "TT": 55, "TB": 64 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "4'", "P": 117, "S": 71, "T": 64, "TT": 53, "TB": 63 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 49, "S": 34, "T": 38, "TT": 27, "TB": 22 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "4'", "P": 26, "S": 26, "T": 30, "TT": 26, "TB": 0 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "4'", "P": 124, "S": 88, "T": 81, "TT": 63, "TB": 61 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "4'", "P": 97, "S": 60, "T": 48, "TT": 45, "TB": 52 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "4'", "P": 78, "S": 64, "T": 52, "TT": 38, "TB": 40 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 117, "S": 77, "T": 63, "TT": 43, "TB": 74 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 14, "S": 14, "T": 18, "TT": 14, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "4'", "P": 28, "S": 26, "T": 27, "TT": 28, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "4'", "P": 35, "S": 28, "T": 32, "TT": 14, "TB": 21 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 14, "S": 14, "T": 18, "TT": 0, "TB": 14 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "4'", "P": 23, "S": 20, "T": 24, "TT": 8, "TB": 15 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "4'", "P": 62, "S": 38, "T": 42, "TT": 30, "TB": 32 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "4'", "P": 24, "S": 24, "T": 28, "TT": 12, "TB": 12 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "4'", "P": 26, "S": 26, "T": 30, "TT": 7, "TB": 19 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 46, "S": 31, "T": 28, "TT": 23, "TB": 23 },
    { "LETTER": "?", "WIDTH": 3, "HEIGHT": "4'", "P": 64, "S": 52, "T": 54, "TT": 44, "TB": 20 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 149, "S": 102, "T": 71, "TT": 75, "TB": 74 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "5'", "P": 170, "S": 76, "T": 76, "TT": 88, "TB": 82 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "5'", "P": 81, "S": 40, "T": 44, "TT": 46, "TB": 35 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "5'", "P": 142, "S": 74, "T": 77, "TT": 75, "TB": 67 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "5'", "P": 158, "S": 82, "T": 82, "TT": 82, "TB": 76 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 147, "S": 62, "T": 57, "TT": 71, "TB": 76 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "5'", "P": 179, "S": 84, "T": 83, "TT": 107, "TB": 77 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "5'", "P": 186, "S": 83, "T": 76, "TT": 102, "TB": 84 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 133, "S": 68, "T": 72, "TT": 92, "TB": 41 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "5'", "P": 190, "S": 80, "T": 76, "TT": 99, "TB": 91 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "5'", "P": 186, "S": 83, "T": 76, "TT": 93, "TB": 93 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 66, "S": 40, "T": 43, "TT": 38, "TB": 28 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "5'", "P": 34, "S": 28, "T": 31, "TT": 34, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "5'", "P": 248, "S": 112, "T": 104, "TT": 126, "TB": 122 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "5'", "P": 180, "S": 82, "T": 60, "TT": 94, "TB": 86 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "5'", "P": 127, "S": 100, "T": 92, "TT": 65, "TB": 62 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 215, "S": 93, "T": 80, "TT": 93, "TB": 122 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 19, "S": 16, "T": 20, "TT": 19, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "5'", "P": 33, "S": 26, "T": 27, "TT": 33, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "5'", "P": 63, "S": 32, "T": 36, "TT": 27, "TB": 36 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "5'", "P": 43, "S": 24, "T": 28, "TT": 17, "TB": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 17, "S": 14, "T": 18, "TT": 0, "TB": 17 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "5'", "P": 87, "S": 52, "T": 56, "TT": 45, "TB": 42 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "5'", "P": 34, "S": 28, "T": 31, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "5'", "P": 36, "S": 30, "T": 33, "TT": 17, "TB": 19 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 86, "S": 38, "T": 34, "TT": 43, "TB": 43 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "5'", "P": 109, "S": 66, "T": 69, "TT": 80, "TB": 29 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 295, "S": 131, "T": 81, "TT": 154, "TB": 141 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "6'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "6'", "P": 200, "S": 88, "T": 88, "TT": 98, "TB": 102 },
    { "LETTER": "1", "WIDTH": 2.5, "HEIGHT": "6'", "P": 96, "S": 46, "T": 50, "TT": 51, "TB": 45 },
    { "LETTER": "2", "WIDTH": 3.5, "HEIGHT": "6'", "P": 173, "S": 88, "T": 90, "TT": 86, "TB": 87 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "6'", "P": 193, "S": 95, "T": 98, "TT": 93, "TB": 100 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "6'", "P": 179, "S": 76, "T": 71, "TT": 80, "TB": 99 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "6'", "P": 199, "S": 92, "T": 96, "TT": 107, "TB": 92 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "6'", "P": 211, "S": 94, "T": 92, "TT": 107, "TB": 104 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "6'", "P": 146, "S": 80, "T": 82, "TT": 95, "TB": 51 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "6'", "P": 217, "S": 94, "T": 88, "TT": 105, "TB": 112 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "6'", "P": 211, "S": 94, "T": 92, "TT": 98, "TB": 113 },
    { "LETTER": "!", "WIDTH": 1.5, "HEIGHT": "6'", "P": 81, "S": 46, "T": 49, "TT": 43, "TB": 38 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "6'", "P": 48, "S": 34, "T": 38, "TT": 48, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "6'", "P": 280, "S": 128, "T": 20, "TT": 138, "TB": 142 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "6'", "P": 198, "S": 95, "T": 80, "TT": 96, "TB": 102 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "6'", "P": 150, "S": 102, "T": 110, "TT": 74, "TB": 76 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "6'", "P": 257, "S": 111, "T": 103, "TT": 101, "TB": 155 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "6'", "P": 22, "S": 17, "T": 21, "TT": 22, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "6'", "P": 40, "S": 32, "T": 31, "TT": 40, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "6'", "P": 73, "S": 36, "T": 40, "TT": 32, "TB": 41 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "6'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "6'", "P": 43, "S": 24, "T": 28, "TT": 17, "TB": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "6'", "P": 17, "S": 14, "T": 18, "TT": 0, "TB": 17 },
    { "LETTER": "/", "WIDTH": 4, "HEIGHT": "6'", "P": 98, "S": 62, "T": 66, "TT": 48, "TB": 50 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "6'", "P": 34, "S": 28, "T": 36, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "6'", "P": 41, "S": 33, "T": 40, "TT": 17, "TB": 24 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "6'", "P": 86, "S": 48, "T": 45, "TT": 43, "TB": 43 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "6'", "P": 130, "S": 72, "T": 75, "TT": 90, "TB": 40 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "6'", "P": 352, "S": 161, "T": 138, "TT": 175, "TB": 177 },
    { "LETTER": 'A', "WIDTH": 5, "HEIGHT": "5'", "P": 271, "S": 73, "T": 73 },
    { "LETTER": 'B', "WIDTH": 5, "HEIGHT": "5'", "P": 373, "S": 93, "T": 89 },
    { "LETTER": 'C', "WIDTH": 5, "HEIGHT": "5'", "P": 245, "S": 88, "T": 92 },
    { "LETTER": 'D', "WIDTH": 5, "HEIGHT": "5'", "P": 320, "S": 88, "T": 88 },
    { "LETTER": 'E', "WIDTH": 5, "HEIGHT": "5'", "P": 352, "S": 103, "T": 107 },
    { "LETTER": 'F', "WIDTH": 5, "HEIGHT": "5'", "P": 271, "S": 80, "T": 84 },
    { "LETTER": 'G', "WIDTH": 5, "HEIGHT": "5'", "P": 321, "S": 106, "T": 96 },
    { "LETTER": 'H', "WIDTH": 5, "HEIGHT": "5'", "P": 308, "S": 84, "T": 88 },
    { "LETTER": 'I', "WIDTH": 2.5, "HEIGHT": "5'", "P": 131, "S": 40, "T": 44 },
    { "LETTER": 'J', "WIDTH": 5, "HEIGHT": "5'", "P": 183, "S": 62, "T": 66 },
    { "LETTER": 'K', "WIDTH": 5, "HEIGHT": "5'", "P": 261, "S": 87, "T": 86 },
    { "LETTER": 'L', "WIDTH": 5, "HEIGHT": "5'", "P": 208, "S": 62, "T": 66 },
    { "LETTER": 'M', "WIDTH": 5, "HEIGHT": "5'", "P": 354, "S": 90, "T": 87 },
    { "LETTER": 'N', "WIDTH": 5, "HEIGHT": "5'", "P": 370, "S": 90, "T": 88 },
    { "LETTER": 'O', "WIDTH": 5, "HEIGHT": "5'", "P": 288, "S": 88, "T": 86 },
    { "LETTER": 'P', "WIDTH": 5, "HEIGHT": "5'", "P": 288, "S": 77, "T": 77 },
    { "LETTER": 'Q', "WIDTH": 5, "HEIGHT": "5'", "P": 316, "S": 96, "T": 84 },
    { "LETTER": 'R', "WIDTH": 5, "HEIGHT": "5'", "P": 338, "S": 95, "T": 94 },
    { "LETTER": 'S', "WIDTH": 5, "HEIGHT": "5'", "P": 329, "S": 110, "T": 94 },
    { "LETTER": 'T', "WIDTH": 5, "HEIGHT": "5'", "P": 208, "S": 62, "T": 66 },
    { "LETTER": 'U', "WIDTH": 5, "HEIGHT": "5'", "P": 275, "S": 84, "T": 87 },
    { "LETTER": 'V', "WIDTH": 5, "HEIGHT": "5'", "P": 253, "S": 79, "T": 80 },
    { "LETTER": 'W', "WIDTH": 8, "HEIGHT": "5'", "P": 436, "S": 117, "T": 112 },
    { "LETTER": 'X', "WIDTH": 5, "HEIGHT": "5'", "P": 268, "S": 96, "T": 96 },
    { "LETTER": 'Y', "WIDTH": 5, "HEIGHT": "5'", "P": 196, "S": 70, "T": 73 },
    { "LETTER": 'Z', "WIDTH": 5, "HEIGHT": "5'", "P": 306, "S": 94, "T": 94 },
    { "LETTER": 'A', "WIDTH": 4, "HEIGHT": "4'", "P": 153, "S": 61, "T": 57 },
    { "LETTER": 'B', "WIDTH": 4, "HEIGHT": "4'", "P": 215, "S": 71, "T": 67 },
    { "LETTER": 'C', "WIDTH": 4, "HEIGHT": "4'", "P": 167, "S": 72, "T": 76 },
    { "LETTER": 'D', "WIDTH": 4, "HEIGHT": "4'", "P": 196, "S": 68, "T": 68 },
    { "LETTER": 'E', "WIDTH": 4, "HEIGHT": "4'", "P": 194, "S": 77, "T": 81 },
    { "LETTER": 'F', "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 60, "T": 64 },
    { "LETTER": 'G', "WIDTH": 4, "HEIGHT": "4'", "P": 195, "S": 77, "T": 69 },
    { "LETTER": 'H', "WIDTH": 4, "HEIGHT": "4'", "P": 185, "S": 66, "T": 70 },
    { "LETTER": 'I', "WIDTH": 2, "HEIGHT": "4'", "P": 81, "S": 32, "T": 36 },
    { "LETTER": 'J', "WIDTH": 4, "HEIGHT": "4'", "P": 112, "S": 48, "T": 52 },
    { "LETTER": 'K', "WIDTH": 4, "HEIGHT": "4'", "P": 170, "S": 74, "T": 73 },
    { "LETTER": 'L', "WIDTH": 4, "HEIGHT": "4'", "P": 121, "S": 48, "T": 52 },
    { "LETTER": 'M', "WIDTH": 4, "HEIGHT": "4'", "P": 225, "S": 65, "T": 61 },
    { "LETTER": 'N', "WIDTH": 4, "HEIGHT": "4'", "P": 213, "S": 70, "T": 68 },
    { "LETTER": 'O', "WIDTH": 4, "HEIGHT": "4'", "P": 186, "S": 68, "T": 68 },
    { "LETTER": 'P', "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 60, "T": 59 },
    { "LETTER": 'Q', "WIDTH": 4, "HEIGHT": "4'", "P": 201, "S": 74, "T": 64 },
    { "LETTER": 'R', "WIDTH": 4, "HEIGHT": "4'", "P": 200, "S": 74, "T": 70 },
    { "LETTER": 'S', "WIDTH": 4, "HEIGHT": "4'", "P": 187, "S": 84, "T": 68 },
    { "LETTER": 'T', "WIDTH": 4, "HEIGHT": "4'", "P": 121, "S": 48, "T": 52 },
    { "LETTER": 'U', "WIDTH": 4, "HEIGHT": "4'", "P": 174, "S": 66, "T": 70 },
    { "LETTER": 'V', "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 59, "T": 60 },
    { "LETTER": 'W', "WIDTH": 6, "HEIGHT": "4'", "P": 289, "S": 102, "T": 97 },
    { "LETTER": 'X', "WIDTH": 4, "HEIGHT": "4'", "P": 167, "S": 74, "T": 72 },
    { "LETTER": 'Y', "WIDTH": 4, "HEIGHT": "4'", "P": 132, "S": 58, "T": 60 },
    { "LETTER": 'Z', "WIDTH": 4, "HEIGHT": "4'", "P": 181, "S": 72, "T": 72 },
];

export const standardFontData = [
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "3'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "1", "WIDTH": 1.5, "HEIGHT": "3'", "P": 25, "S": 35, "T": 35 },
    { "LETTER": "2", "WIDTH": 2.5, "HEIGHT": "3'", "P": 56, "S": 44, "T": 42 },
    { "LETTER": "3", "WIDTH": 2.5, "HEIGHT": "3'", "P": 54, "S": 42, "T": 40 },
    { "LETTER": "4", "WIDTH": 2.5, "HEIGHT": "3'", "P": 44, "S": 34, "T": 38 },
    { "LETTER": "5", "WIDTH": 2.5, "HEIGHT": "3'", "P": 57, "S": 44, "T": 41 },
    { "LETTER": "6", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 34, "T": 34 },
    { "LETTER": "7", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 28, "T": 32 },
    { "LETTER": "8", "WIDTH": 2.5, "HEIGHT": "3'", "P": 67, "S": 40, "T": 36 },
    { "LETTER": "9", "WIDTH": 2.5, "HEIGHT": "3'", "P": 51, "S": 34, "T": 34 },
    { "LETTER": "0", "WIDTH": 2.5, "HEIGHT": "3'", "P": 60, "S": 40, "T": 40 },
    { "LETTER": "-", "WIDTH": 1.5, "HEIGHT": "3'", "P": 3, "S": 8, "T": 12 },
    { "LETTER": ".", "WIDTH": 1, "HEIGHT": "3'", "P": 1, "S": 4, "T": 8 },
    { "LETTER": "!", "WIDTH": 1.5, "HEIGHT": "3'", "P": 20, "S": 23, "T": 24 },
    { "LETTER": "\\", "WIDTH": 2, "HEIGHT": "3'", "P": 10, "S": 16, "T": 17 },
    { "LETTER": "'", "WIDTH": 1.5, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13 },
    { "LETTER": ",", "WIDTH": 1.5, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13 },
    { "LETTER": "#", "WIDTH": 2.5, "HEIGHT": "3'", "P": 28, "S": 14, "T": 38 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 73, "S": 51, "T": 40 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 78, "S": 33, "T": 64 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "3.5'", "P": 58, "S": 14, "T": 52 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "3'", "P": 40, "S": 54, "T": 58 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 34, "T": 32 },
    { "LETTER": "A", "WIDTH": 2, "HEIGHT": "3'", "P": 60, "S": 40, "T": 40 },
    { "LETTER": "B", "WIDTH": 2, "HEIGHT": "3'", "P": 65, "S": 46, "T": 46 },
    { "LETTER": "C", "WIDTH": 2, "HEIGHT": "3'", "P": 51, "S": 44, "T": 44 },
    { "LETTER": "D", "WIDTH": 2, "HEIGHT": "3'", "P": 55, "S": 42, "T": 42 },
    { "LETTER": "E", "WIDTH": 2, "HEIGHT": "3'", "P": 51, "S": 42, "T": 42 },
    { "LETTER": "F", "WIDTH": 2, "HEIGHT": "3'", "P": 42, "S": 40, "T": 40 },
    { "LETTER": "G", "WIDTH": 2, "HEIGHT": "3'", "P": 51, "S": 43, "T": 43 },
    { "LETTER": "H", "WIDTH": 2, "HEIGHT": "3'", "P": 55, "S": 52, "T": 52 },
    { "LETTER": "I", "WIDTH": 2, "HEIGHT": "3'", "P": 25, "S": 35, "T": 35 },
    { "LETTER": "J", "WIDTH": 2, "HEIGHT": "3'", "P": 42, "S": 36, "T": 36 },
    { "LETTER": "K", "WIDTH": 2, "HEIGHT": "3'", "P": 53, "S": 52, "T": 52 },
    { "LETTER": "L", "WIDTH": 2, "HEIGHT": "3'", "P": 32, "S": 30, "T": 30 },
    { "LETTER": "M", "WIDTH": 2, "HEIGHT": "3'", "P": 66, "S": 52, "T": 52 },
    { "LETTER": "N", "WIDTH": 2, "HEIGHT": "3'", "P": 62, "S": 56, "T": 56 },
    { "LETTER": "O", "WIDTH": 2, "HEIGHT": "3'", "P": 58, "S": 51, "T": 51 },
    { "LETTER": "P", "WIDTH": 2, "HEIGHT": "3'", "P": 51, "S": 42, "T": 42 },
    { "LETTER": "Q", "WIDTH": 2, "HEIGHT": "3'", "P": 60, "S": 46, "T": 46 },
    { "LETTER": "R", "WIDTH": 2, "HEIGHT": "3'", "P": 61, "S": 42, "T": 42 },
    { "LETTER": "S", "WIDTH": 2, "HEIGHT": "3'", "P": 46, "S": 46, "T": 46 },
    { "LETTER": "T", "WIDTH": 2, "HEIGHT": "3'", "P": 36, "S": 42, "T": 42 },
    { "LETTER": "U", "WIDTH": 2, "HEIGHT": "3'", "P": 52, "S": 48, "T": 48 },
    { "LETTER": "V", "WIDTH": 2, "HEIGHT": "3'", "P": 48, "S": 53, "T": 53 },
    { "LETTER": "W", "WIDTH": 2, "HEIGHT": "3'", "P": 56, "S": 56, "T": 56 },
    { "LETTER": "X", "WIDTH": 2, "HEIGHT": "3'", "P": 53, "S": 56, "T": 56 },
    { "LETTER": "Y", "WIDTH": 2, "HEIGHT": "3'", "P": 38, "S": 45, "T": 45 },
    { "LETTER": "Z", "WIDTH": 2, "HEIGHT": "3'", "P": 53, "S": 47, "T": 47 }
]

export const horseBackFontData = [
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "6'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 5, "HEIGHT": "6'", "P": 354, "S": 90, "T": 90, "TT": 172, "TB": 182 },
    { "LETTER": "1", "WIDTH": 4, "HEIGHT": "6'", "P": 249, "S": 72, "T": 74, "TT": 112, "TB": 137 },
    { "LETTER": "2", "WIDTH": 6, "HEIGHT": "6'", "P": 348, "S": 121, "T": 122, "TT": 161, "TB": 187 },
    { "LETTER": "3", "WIDTH": 5, "HEIGHT": "6'", "P": 340, "S": 116, "T": 117, "TT": 166, "TB": 174 },
    { "LETTER": "4", "WIDTH": 5, "HEIGHT": "6'", "P": 319, "S": 86, "T": 82, "TT": 150, "TB": 169 },
    { "LETTER": "5", "WIDTH": 6, "HEIGHT": "6'", "P": 371, "S": 121, "T": 122, "TT": 190, "TB": 181 },
    { "LETTER": "6", "WIDTH": 6, "HEIGHT": "6'", "P": 411, "S": 118, "T": 108, "TT": 197, "TB": 214 },
    { "LETTER": "7", "WIDTH": 6, "HEIGHT": "6'", "P": 305, "S": 94, "T": 96, "TT": 200, "TB": 105 },
    { "LETTER": "8", "WIDTH": 5, "HEIGHT": "6'", "P": 425, "S": 108, "T": 102, "TT": 206, "TB": 219 },
    { "LETTER": "9", "WIDTH": 6, "HEIGHT": "6'", "P": 415, "S": 121, "T": 110, "TT": 199, "TB": 216 },
    { "LETTER": "!", "WIDTH": 3, "HEIGHT": "6'", "P": 161, "S": 58, "T": 61, "TT": 101, "TB": 60 },
    { "LETTER": "\\", "WIDTH": 4, "HEIGHT": "6'", "P": 102, "S": 50, "T": 53, "TT": 102, "TB": 0 },
    { "LETTER": "#", "WIDTH": 8, "HEIGHT": "6'", "P": 372, "S": 144, "T": 131, "TT": 183, "TB": 189 },
    { "LETTER": "$", "WIDTH": 6, "HEIGHT": "6'", "P": 396, "S": 120, "T": 110, "TT": 191, "TB": 205 },
    { "LETTER": "%", "WIDTH": 5, "HEIGHT": "6'", "P": 223, "S": 132, "T": 128, "TT": 110, "TB": 113 },
    { "LETTER": "&", "WIDTH": 6, "HEIGHT": "6'", "P": 399, "S": 113, "T": 101, "TT": 155, "TB": 244 },
    { "LETTER": "a'", "WIDTH": 2.5, "HEIGHT": "6'", "P": 48, "S": 25, "T": 29, "TT": 48, "TB": 0 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "6'", "P": 84, "S": 45, "T": 47, "TT": 84, "TB": 0 },
    { "LETTER": "+", "WIDTH": 4, "HEIGHT": "6'", "P": 160, "S": 50, "T": 54, "TT": 74, "TB": 86 },
    { "LETTER": ",", "WIDTH": 2.5, "HEIGHT": "6'", "P": 43, "S": 23, "T": 27, "TT": 0, "TB": 43 },
    { "LETTER": "-", "WIDTH": 4, "HEIGHT": "6'", "P": 88, "S": 34, "T": 38, "TT": 38, "TB": 50 },
    { "LETTER": ".", "WIDTH": 2.5, "HEIGHT": "6'", "P": 37, "S": 20, "T": 24, "TT": 0, "TB": 37 },
    { "LETTER": "/", "WIDTH": 5, "HEIGHT": "6'", "P": 135, "S": 68, "T": 72, "TT": 66, "TB": 69 },
    { "LETTER": ":", "WIDTH": 2.5, "HEIGHT": "6'", "P": 74, "S": 40, "T": 48, "TT": 37, "TB": 37 },
    { "LETTER": ";", "WIDTH": 2.5, "HEIGHT": "6'", "P": 85, "S": 45, "T": 53, "TT": 37, "TB": 48 },
    { "LETTER": "=", "WIDTH": 4, "HEIGHT": "6'", "P": 176, "S": 68, "T": 61, "TT": 88, "TB": 88 },
    { "LETTER": "?", "WIDTH": 5, "HEIGHT": "6'", "P": 241, "S": 94, "T": 92, "TT": 175, "TB": 66 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "6'", "P": 430, "S": 153, "T": 102, "TT": 218, "TB": 212 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "5'", "P": 228, "S": 80, "T": 80, "TT": 110, "TB": 118 },
    { "LETTER": "1", "WIDTH": 3.5, "HEIGHT": "5'", "P": 152, "S": 56, "T": 60, "TT": 69, "TB": 83 },
    { "LETTER": "2", "WIDTH": 5, "HEIGHT": "5'", "P": 243, "S": 101, "T": 100, "TT": 100, "TB": 143 },
    { "LETTER": "3", "WIDTH": 5, "HEIGHT": "5'", "P": 224, "S": 99, "T": 102, "TT": 108, "TB": 116 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 193, "S": 72, "T": 69, "TT": 75, "TB": 118 },
    { "LETTER": "5", "WIDTH": 5, "HEIGHT": "5'", "P": 235, "S": 99, "T": 99, "TT": 107, "TB": 128 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "5'", "P": 239, "S": 93, "T": 91, "TT": 100, "TB": 139 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 151, "S": 63, "T": 66, "TT": 96, "TB": 55 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "5'", "P": 259, "S": 92, "T": 88, "TT": 124, "TB": 135 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "5'", "P": 239, "S": 93, "T": 91, "TT": 127, "TB": 112 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 102, "S": 46, "T": 50, "TT": 69, "TB": 33 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "5'", "P": 62, "S": 38, "T": 41, "TT": 62, "TB": 0 },
    { "LETTER": "#", "WIDTH": 7, "HEIGHT": "5'", "P": 267, "S": 120, "T": 114, "TT": 135, "TB": 132 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "5'", "P": 229, "S": 80, "T": 66, "TT": 109, "TB": 120 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "5'", "P": 122, "S": 94, "T": 94, "TT": 60, "TB": 62 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 264, "S": 97, "T": 83, "TT": 107, "TB": 157 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 19, "T": 23, "TT": 29, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "5'", "P": 38, "S": 30, "T": 29, "TT": 38, "TB": 0 },
    { "LETTER": "+", "WIDTH": 4, "HEIGHT": "5'", "P": 114, "S": 46, "T": 50, "TT": 51, "TB": 63 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 19, "T": 23, "TT": 0, "TB": 29 },
    { "LETTER": "-", "WIDTH": 4, "HEIGHT": "5'", "P": 58, "S": 30, "T": 34, "TT": 23, "TB": 35 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 24, "S": 16, "T": 20, "TT": 0, "TB": 24 },
    { "LETTER": "/", "WIDTH": 4, "HEIGHT": "5'", "P": 82, "S": 54, "T": 58, "TT": 40, "TB": 42 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "5'", "P": 48, "S": 32, "T": 40, "TT": 24, "TB": 24 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "5'", "P": 53, "S": 35, "T": 43, "TT": 24, "TB": 29 },
    { "LETTER": "=", "WIDTH": 4, "HEIGHT": "5'", "P": 116, "S": 60, "T": 54, "TT": 58, "TB": 58 },
    { "LETTER": "?", "WIDTH": 4, "HEIGHT": "5'", "P": 143, "S": 73, "T": 76, "TT": 90, "TB": 53 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "5'", "P": 289, "S": 130, "T": 83, "TT": 148, "TB": 141 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "4'", "P": 176, "S": 64, "T": 64, "TT": 85, "TB": 91 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "4'", "P": 112, "S": 44, "T": 48, "TT": 50, "TB": 62 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 70, "T": 69, "TT": 71, "TB": 97 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "4'", "P": 161, "S": 74, "T": 66, "TT": 77, "TB": 84 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 58, "T": 53, "TT": 71, "TB": 80 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 74, "T": 67, "TT": 74, "TB": 85 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "4'", "P": 162, "S": 65, "T": 58, "TT": 73, "TB": 89 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "4'", "P": 117, "S": 52, "T": 55, "TT": 73, "TB": 44 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "4'", "P": 161, "S": 58, "T": 50, "TT": 70, "TB": 91 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "4'", "P": 162, "S": 65, "T": 58, "TT": 80, "TB": 82 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 64, "S": 35, "T": 36, "TT": 37, "TB": 27 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "4'", "P": 44, "S": 32, "T": 34, "TT": 44, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "4'", "P": 168, "S": 84, "T": 75, "TT": 82, "TB": 86 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 78, "T": 58, "TT": 75, "TB": 84 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "4'", "P": 78, "S": 64, "T": 52, "TT": 38, "TB": 40 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 166, "S": 65, "T": 55, "TT": 63, "TB": 103 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 19, "T": 22, "TT": 23, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "4'", "P": 36, "S": 28, "T": 29, "TT": 36, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "", "P": 90, "S": 38, "T": 42, "TT": 40, "TB": 50 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3.5, "HEIGHT": "", "P": 48, "S": 26, "T": 30, "TT": 19, "TB": 29 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "4'", "P": 62, "S": 38, "T": 42, "TT": 30, "TB": 32 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "4'", "P": 34, "S": 28, "T": 36, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "4'", "P": 40, "S": 33, "T": 40, "TT": 17, "TB": 23 },
    { "LETTER": "=", "WIDTH": 3.5, "HEIGHT": "4", "P": 96, "S": 52, "T": 48, "TT": 48, "TB": 48 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "4'", "P": 98, "S": 57, "T": 53, "TT": 67, "TB": 31 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 180, "S": 93, "T": 53, "TT": 92, "TB": 88 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "3'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 2, "HEIGHT": "3'", "P": 48, "S": 40, "T": 38, "TT": 23, "TB": 25 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "3'", "P": 33, "S": 26, "T": 30, "TT": 15, "TB": 18 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "3'", "P": 57, "S": 46, "T": 42, "TT": 21, "TB": 36 },
    { "LETTER": "3", "WIDTH": 3, "HEIGHT": "3'", "P": 53, "S": 46, "T": 41, "TT": 25, "TB": 28 },
    { "LETTER": "4", "WIDTH": 3, "HEIGHT": "3'", "P": 55, "S": 38, "T": 36, "TT": 27, "TB": 28 },
    { "LETTER": "5", "WIDTH": 3, "HEIGHT": "3'", "P": 60, "S": 50, "T": 43, "TT": 31, "TB": 29 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "3'", "P": 65, "S": 47, "T": 41, "TT": 27, "TB": 38 },
    { "LETTER": "7", "WIDTH": 3, "HEIGHT": "3'", "P": 39, "S": 34, "T": 37, "TT": 25, "TB": 14 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "3'", "P": 78, "S": 44, "T": 36, "TT": 35, "TB": 43 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "3'", "P": 59, "S": 46, "T": 39, "TT": 31, "TB": 28 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "3'", "P": 25, "S": 25, "T": 26, "TT": 16, "TB": 9 },
    { "LETTER": "\\", "WIDTH": 2, "HEIGHT": "3'", "P": 26, "S": 23, "T": 24, "TT": 26, "TB": 0 },
    { "LETTER": "#", "WIDTH": 3.5, "HEIGHT": "3'", "P": 80, "S": 56, "T": 40, "TT": 39, "TB": 41 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "3'", "P": 75, "S": 44, "T": 36, "TT": 34, "TB": 41 },
    { "LETTER": "%", "WIDTH": 2, "HEIGHT": "3'", "P": 35, "S": 38, "T": 36, "TT": 17, "TB": 18 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 59, "S": 41, "T": 34, "TT": 21, "TB": 38 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 14, "T": 18, "TT": 14, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 16, "T": 19, "TT": 14, "TB": 0 },
    { "LETTER": "+", "WIDTH": 2, "HEIGHT": "3'", "P": 29, "S": 24, "T": 28, "TT": 12, "TB": 17 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 14, "T": 18, "TT": 0, "TB": 14 },
    { "LETTER": "-", "WIDTH": 2, "HEIGHT": "3'", "P": 17, "S": 16, "T": 20, "TT": 6, "TB": 11 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "3'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 2, "HEIGHT": "3'", "P": 27, "S": 28, "T": 32, "TT": 13, "TB": 14 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "3'", "P": 24, "S": 21, "T": 22, "TT": 12, "TB": 12 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "3'", "P": 26, "S": 23, "T": 24, "TT": 12, "TB": 14 },
    { "LETTER": "=", "WIDTH": 2, "HEIGHT": "3'", "P": 34, "S": 25, "T": 24, "TT": 17, "TB": 17 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 31, "S": 33, "T": 30, "TT": 21, "TB": 10 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 77, "S": 47, "T": 36, "TT": 38, "TB": 39 },
    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "4'", "P": 166, "S": 71, "T": 67 },
    { "LETTER": "B", "WIDTH": 4, "HEIGHT": "4'", "P": 181, "S": 74, "T": 68 },
    { "LETTER": "C", "WIDTH": 4, "HEIGHT": "4'", "P": 137, "S": 70, "T": 74 },
    { "LETTER": "D", "WIDTH": 4, "HEIGHT": "4'", "P": 169, "S": 71, "T": 71 },
    { "LETTER": "E", "WIDTH": 4, "HEIGHT": "4'", "P": 178, "S": 77, "T": 74 },
    { "LETTER": "F", "WIDTH": 4, "HEIGHT": "4'", "P": 148, "S": 65, "T": 64 },
    { "LETTER": "G", "WIDTH": 4, "HEIGHT": "4'", "P": 179, "S": 82, "T": 75 },
    { "LETTER": "H", "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "I", "WIDTH": 3, "HEIGHT": "4'", "P": 98, "S": 46, "T": 50 },
    { "LETTER": "J", "WIDTH": 3, "HEIGHT": "4'", "P": 88, "S": 44, "T": 48 },
    { "LETTER": "K", "WIDTH": 4, "HEIGHT": "4'", "P": 152, "S": 78, "T": 77 },
    { "LETTER": "L", "WIDTH": 4, "HEIGHT": "4'", "P": 58, "S": 62, "T": 62 },
    { "LETTER": "M", "WIDTH": 6, "HEIGHT": "4'", "P": 245, "S": 105, "T": 99 },
    { "LETTER": "N", "WIDTH": 4, "HEIGHT": "4'", "P": 183, "S": 66, "T": 62 },
    { "LETTER": "O", "WIDTH": 4, "HEIGHT": "4'", "P": 158, "S": 70, "T": 70 },
    { "LETTER": "P", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 65, "T": 63 },
    { "LETTER": "Q", "WIDTH": 4, "HEIGHT": "4'", "P": 162, "S": 70, "T": 68 },
    { "LETTER": "R", "WIDTH": 4, "HEIGHT": "4'", "P": 192, "S": 78, "T": 67 },
    { "LETTER": "S", "WIDTH": 4, "HEIGHT": "4'", "P": 187, "S": 88, "T": 76 },
    { "LETTER": "T", "WIDTH": 4, "HEIGHT": "4'", "P": 129, "S": 58, "T": 62 },
    { "LETTER": "U", "WIDTH": 4, "HEIGHT": "4'", "P": 138, "S": 66, "T": 70 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "4'", "P": 119, "S": 59, "T": 60 },
    { "LETTER": "W", "WIDTH": 7, "HEIGHT": "4'", "P": 223, "S": 103, "T": 98 },
    { "LETTER": "X", "WIDTH": 4, "HEIGHT": "4'", "P": 149, "S": 78, "T": 78 },
    { "LETTER": "Y", "WIDTH": 4, "HEIGHT": "4'", "P": 124, "S": 62, "T": 64 },
    { "LETTER": "Z", "WIDTH": 4, "HEIGHT": "4'", "P": 163, "S": 80, "T": 80 },
    { "LETTER": "A", "WIDTH": 6, "HEIGHT": "5'", "P": 257, "S": 93, "T": 87 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "5'", "P": 284, "S": 98, "T": 92 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "5'", "P": 209, "S": 95, "T": 98 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "5'", "P": 246, "S": 92, "T": 92 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "5'", "P": 270, "S": 102, "T": 100 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "5'", "P": 221, "S": 84, "T": 85 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "5'", "P": 263, "S": 109, "T": 103 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "5'", "P": 261, "S": 92, "T": 96 },
    { "LETTER": "I", "WIDTH": 3, "HEIGHT": "5'", "P": 142, "S": 54, "T": 58 },
    { "LETTER": "J", "WIDTH": 3.5, "HEIGHT": "5'", "P": 126, "S": 54, "T": 58 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "5'", "P": 240, "S": 97, "T": 96 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "5'", "P": 191, "S": 72, "T": 76 },
    { "LETTER": "M", "WIDTH": 7, "HEIGHT": "5'", "P": 382, "S": 123, "T": 117 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "5'", "P": 293, "S": 86, "T": 82 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "5'", "P": 214, "S": 92, "T": 92 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "5'", "P": 236, "S": 84, "T": 84 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "5'", "P": 226, "S": 92, "T": 90 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "5'", "P": 285, "S": 104, "T": 96 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "5'", "P": 275, "S": 114, "T": 110 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "5'", "P": 211, "S": 78, "T": 82 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "5'", "P": 231, "S": 84, "T": 88 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "5'", "P": 160, "S": 80, "T": 81 },
    { "LETTER": "W", "WIDTH": 8, "HEIGHT": "5'", "P": 327, "S": 129, "T": 124 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "5'", "P": 223, "S": 98, "T": 96 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "5'", "P": 178, "S": 79, "T": 81 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "5'", "P": 269, "S": 104, "T": 104 },
    { "LETTER": "A", "WIDTH": 7, "HEIGHT": "6'", "P": 346, "S": 111, "T": 106, "TT": 124, "TB": 222 },
    { "LETTER": "B", "WIDTH": 6, "HEIGHT": "6'", "P": 415, "S": 116, "T": 110, "TT": 159, "TB": 256 },
    { "LETTER": "C", "WIDTH": 6, "HEIGHT": "6'", "P": 293, "S": 110, "T": 113, "TT": 147, "TB": 146 },
    { "LETTER": "D", "WIDTH": 6, "HEIGHT": "6'", "P": 346, "S": 108, "T": 108, "TT": 162, "TB": 184 },
    { "LETTER": "E", "WIDTH": 6, "HEIGHT": "6'", "P": 368, "S": 114, "T": 118, "TT": 154, "TB": 214 },
    { "LETTER": "F", "WIDTH": 6, "HEIGHT": "6'", "P": 291, "S": 92, "T": 96, "TT": 154, "TB": 137 },
    { "LETTER": "G", "WIDTH": 6, "HEIGHT": "6'", "P": 390, "S": 131, "T": 125, "TT": 147, "TB": 243 },
    { "LETTER": "H", "WIDTH": 6, "HEIGHT": "6'", "P": 340, "S": 106, "T": 110, "TT": 126, "TB": 214 },
    { "LETTER": "I", "WIDTH": 3, "HEIGHT": "6'", "P": 179, "S": 60, "T": 64, "TT": 77, "TB": 102 },
    { "LETTER": "J", "WIDTH": 3.5, "HEIGHT": "6'", "P": 163, "S": 60, "T": 64, "TT": 77, "TB": 86 },
    { "LETTER": "K", "WIDTH": 6, "HEIGHT": "6'", "P": 332, "S": 114, "T": 114, "TT": 142, "TB": 190 },
    { "LETTER": "L", "WIDTH": 6, "HEIGHT": "6'", "P": 256, "S": 82, "T": 86, "TT": 77, "TB": 179 },
    { "LETTER": "M", "WIDTH": 8, "HEIGHT": "6'", "P": 514, "S": 150, "T": 146, "TT": 194, "TB": 320 },
    { "LETTER": "N", "WIDTH": 6, "HEIGHT": "6'", "P": 390, "S": 106, "T": 102, "TT": 160, "TB": 230 },
    { "LETTER": "O", "WIDTH": 6, "HEIGHT": "6'", "P": 314, "S": 108, "T": 108, "TT": 132, "TB": 182 },
    { "LETTER": "P", "WIDTH": 6, "HEIGHT": "6'", "P": 323, "S": 98, "T": 98, "TT": 155, "TB": 168 },
    { "LETTER": "Q", "WIDTH": 6, "HEIGHT": "6'", "P": 326, "S": 108, "T": 107, "TT": 125, "TB": 201 },
    { "LETTER": "R", "WIDTH": 6, "HEIGHT": "6'", "P": 381, "S": 122, "T": 119, "TT": 155, "TB": 226 },
    { "LETTER": "S", "WIDTH": 6, "HEIGHT": "6'", "P": 384, "S": 132, "T": 130, "TT": 145, "TB": 239 },
    { "LETTER": "T", "WIDTH": 6, "HEIGHT": "6'", "P": 298, "S": 86, "T": 90, "TT": 175, "TB": 123 },
    { "LETTER": "U", "WIDTH": 6, "HEIGHT": "6'", "P": 292, "S": 98, "T": 102, "TT": 126, "TB": 166 },
    { "LETTER": "V", "WIDTH": 6, "HEIGHT": "6'", "P": 249, "S": 90, "T": 91, "TT": 126, "TB": 123 },
    { "LETTER": "W", "WIDTH": 9, "HEIGHT": "6'", "P": 411, "S": 161, "T": 156, "TT": 183, "TB": 228 },
    { "LETTER": "X", "WIDTH": 6, "HEIGHT": "6'", "P": 324, "S": 114, "T": 114, "TT": 137, "TB": 187 },
    { "LETTER": "Y", "WIDTH": 6, "HEIGHT": "6'", "P": 255, "S": 91, "T": 94, "TT": 122, "TB": 133 },
    { "LETTER": "Z", "WIDTH": 6, "HEIGHT": "6'", "P": 386, "S": 118, "T": 118, "TT": 172, "TB": 214 }
]

export const chunkFiveFontData = [
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "6'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 5, "HEIGHT": "6'", "P": 354, "S": 90, "T": 90, "TT": 172, "TB": 182 },
    { "LETTER": "1", "WIDTH": 4, "HEIGHT": "6'", "P": 249, "S": 72, "T": 74, "TT": 112, "TB": 137 },
    { "LETTER": "2", "WIDTH": 6, "HEIGHT": "6'", "P": 348, "S": 121, "T": 122, "TT": 161, "TB": 187 },
    { "LETTER": "3", "WIDTH": 5, "HEIGHT": "6'", "P": 340, "S": 116, "T": 117, "TT": 166, "TB": 174 },
    { "LETTER": "4", "WIDTH": 5, "HEIGHT": "6'", "P": 319, "S": 86, "T": 82, "TT": 150, "TB": 169 },
    { "LETTER": "5", "WIDTH": 6, "HEIGHT": "6'", "P": 371, "S": 121, "T": 122, "TT": 190, "TB": 181 },
    { "LETTER": "6", "WIDTH": 6, "HEIGHT": "6'", "P": 411, "S": 118, "T": 108, "TT": 197, "TB": 214 },
    { "LETTER": "7", "WIDTH": 6, "HEIGHT": "6'", "P": 305, "S": 94, "T": 96, "TT": 200, "TB": 105 },
    { "LETTER": "8", "WIDTH": 5, "HEIGHT": "6'", "P": 425, "S": 108, "T": 102, "TT": 206, "TB": 219 },
    { "LETTER": "9", "WIDTH": 6, "HEIGHT": "6'", "P": 415, "S": 121, "T": 110, "TT": 199, "TB": 216 },
    { "LETTER": "!", "WIDTH": 3, "HEIGHT": "6'", "P": 161, "S": 58, "T": 61, "TT": 101, "TB": 60 },
    { "LETTER": "\\", "WIDTH": 4, "HEIGHT": "6'", "P": 102, "S": 50, "T": 53, "TT": 102, "TB": 0 },
    { "LETTER": "#", "WIDTH": 8, "HEIGHT": "6'", "P": 372, "S": 144, "T": 131, "TT": 183, "TB": 189 },
    { "LETTER": "$", "WIDTH": 6, "HEIGHT": "6'", "P": 396, "S": 120, "T": 110, "TT": 191, "TB": 205 },
    { "LETTER": "%", "WIDTH": 5, "HEIGHT": "6'", "P": 223, "S": 132, "T": 128, "TT": 110, "TB": 113 },
    { "LETTER": "&", "WIDTH": 6, "HEIGHT": "6'", "P": 399, "S": 113, "T": 101, "TT": 155, "TB": 244 },
    { "LETTER": "a'", "WIDTH": 2.5, "HEIGHT": "6'", "P": 48, "S": 25, "T": 29, "TT": 48, "TB": 0 },
    { "LETTER": "*", "WIDTH": 3.5, "HEIGHT": "6'", "P": 84, "S": 45, "T": 47, "TT": 84, "TB": 0 },
    { "LETTER": "+", "WIDTH": 4, "HEIGHT": "6'", "P": 160, "S": 50, "T": 54, "TT": 74, "TB": 86 },
    { "LETTER": ",", "WIDTH": 2.5, "HEIGHT": "6'", "P": 43, "S": 23, "T": 27, "TT": 0, "TB": 43 },
    { "LETTER": "-", "WIDTH": 4, "HEIGHT": "6'", "P": 88, "S": 34, "T": 38, "TT": 38, "TB": 50 },
    { "LETTER": ".", "WIDTH": 2.5, "HEIGHT": "6'", "P": 37, "S": 20, "T": 24, "TT": 0, "TB": 37 },
    { "LETTER": "/", "WIDTH": 5, "HEIGHT": "6'", "P": 135, "S": 68, "T": 72, "TT": 66, "TB": 69 },
    { "LETTER": ":", "WIDTH": 2.5, "HEIGHT": "6'", "P": 74, "S": 40, "T": 48, "TT": 37, "TB": 37 },
    { "LETTER": ";", "WIDTH": 2.5, "HEIGHT": "6'", "P": 85, "S": 45, "T": 53, "TT": 37, "TB": 48 },
    { "LETTER": "=", "WIDTH": 4, "HEIGHT": "6'", "P": 176, "S": 68, "T": 61, "TT": 88, "TB": 88 },
    { "LETTER": "?", "WIDTH": 5, "HEIGHT": "6'", "P": 241, "S": 94, "T": 92, "TT": 175, "TB": 66 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "6'", "P": 430, "S": 153, "T": 102, "TT": 218, "TB": 212 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "5'", "P": 228, "S": 80, "T": 80, "TT": 110, "TB": 118 },
    { "LETTER": "1", "WIDTH": 3.5, "HEIGHT": "5'", "P": 152, "S": 56, "T": 60, "TT": 69, "TB": 83 },
    { "LETTER": "2", "WIDTH": 5, "HEIGHT": "5'", "P": 243, "S": 101, "T": 100, "TT": 100, "TB": 143 },
    { "LETTER": "3", "WIDTH": 5, "HEIGHT": "5'", "P": 224, "S": 99, "T": 102, "TT": 108, "TB": 116 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 193, "S": 72, "T": 69, "TT": 75, "TB": 118 },
    { "LETTER": "5", "WIDTH": 5, "HEIGHT": "5'", "P": 235, "S": 99, "T": 99, "TT": 107, "TB": 128 },
    { "LETTER": "6", "WIDTH": 4, "HEIGHT": "5'", "P": 239, "S": 93, "T": 91, "TT": 100, "TB": 139 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 151, "S": 63, "T": 66, "TT": 96, "TB": 55 },
    { "LETTER": "8", "WIDTH": 4, "HEIGHT": "5'", "P": 259, "S": 92, "T": 88, "TT": 124, "TB": 135 },
    { "LETTER": "9", "WIDTH": 4, "HEIGHT": "5'", "P": 239, "S": 93, "T": 91, "TT": 127, "TB": 112 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 102, "S": 46, "T": 50, "TT": 69, "TB": 33 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "5'", "P": 62, "S": 38, "T": 41, "TT": 62, "TB": 0 },
    { "LETTER": "#", "WIDTH": 7, "HEIGHT": "5'", "P": 267, "S": 120, "T": 114, "TT": 135, "TB": 132 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "5'", "P": 229, "S": 80, "T": 66, "TT": 109, "TB": 120 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "5'", "P": 122, "S": 94, "T": 94, "TT": 60, "TB": 62 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 264, "S": 97, "T": 83, "TT": 107, "TB": 157 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 19, "T": 23, "TT": 29, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "5'", "P": 38, "S": 30, "T": 29, "TT": 38, "TB": 0 },
    { "LETTER": "+", "WIDTH": 4, "HEIGHT": "5'", "P": 114, "S": 46, "T": 50, "TT": 51, "TB": 63 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 29, "S": 19, "T": 23, "TT": 0, "TB": 29 },
    { "LETTER": "-", "WIDTH": 4, "HEIGHT": "5'", "P": 58, "S": 30, "T": 34, "TT": 23, "TB": 35 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 24, "S": 16, "T": 20, "TT": 0, "TB": 24 },
    { "LETTER": "/", "WIDTH": 4, "HEIGHT": "5'", "P": 82, "S": 54, "T": 58, "TT": 40, "TB": 42 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "5'", "P": 48, "S": 32, "T": 40, "TT": 24, "TB": 24 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "5'", "P": 53, "S": 35, "T": 43, "TT": 24, "TB": 29 },
    { "LETTER": "=", "WIDTH": 4, "HEIGHT": "5'", "P": 116, "S": 60, "T": 54, "TT": 58, "TB": 58 },
    { "LETTER": "?", "WIDTH": 4, "HEIGHT": "5'", "P": 143, "S": 73, "T": 76, "TT": 90, "TB": 53 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "5'", "P": 289, "S": 130, "T": 83, "TT": 148, "TB": 141 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 4, "HEIGHT": "4'", "P": 176, "S": 64, "T": 64, "TT": 85, "TB": 91 },
    { "LETTER": "1", "WIDTH": 3, "HEIGHT": "4'", "P": 112, "S": 44, "T": 48, "TT": 50, "TB": 62 },
    { "LETTER": "2", "WIDTH": 4, "HEIGHT": "4'", "P": 168, "S": 70, "T": 69, "TT": 71, "TB": 97 },
    { "LETTER": "3", "WIDTH": 4, "HEIGHT": "4'", "P": 161, "S": 74, "T": 66, "TT": 77, "TB": 84 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "4'", "P": 151, "S": 58, "T": 53, "TT": 71, "TB": 80 },
    { "LETTER": "5", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 74, "T": 67, "TT": 74, "TB": 85 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "4'", "P": 162, "S": 65, "T": 58, "TT": 73, "TB": 89 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "4'", "P": 117, "S": 52, "T": 55, "TT": 73, "TB": 44 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "4'", "P": 161, "S": 58, "T": 50, "TT": 70, "TB": 91 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "4'", "P": 162, "S": 65, "T": 58, "TT": 80, "TB": 82 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 64, "S": 35, "T": 36, "TT": 37, "TB": 27 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "4'", "P": 44, "S": 32, "T": 34, "TT": 44, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "4'", "P": 168, "S": 84, "T": 75, "TT": 82, "TB": 86 },
    { "LETTER": "$", "WIDTH": 4, "HEIGHT": "4'", "P": 159, "S": 78, "T": 58, "TT": 75, "TB": 84 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "4'", "P": 78, "S": 64, "T": 52, "TT": 38, "TB": 40 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 166, "S": 65, "T": 55, "TT": 63, "TB": 103 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 23, "S": 19, "T": 22, "TT": 23, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "4'", "P": 36, "S": 28, "T": 29, "TT": 36, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3.5, "HEIGHT": "", "P": 90, "S": 38, "T": 42, "TT": 40, "TB": 50 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3.5, "HEIGHT": "", "P": 48, "S": 26, "T": 30, "TT": 19, "TB": 29 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "4'", "P": 62, "S": 38, "T": 42, "TT": 30, "TB": 32 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "4'", "P": 34, "S": 28, "T": 36, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "4'", "P": 40, "S": 33, "T": 40, "TT": 17, "TB": 23 },
    { "LETTER": "=", "WIDTH": 3.5, "HEIGHT": "4", "P": 96, "S": 52, "T": 48, "TT": 48, "TB": 48 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "4'", "P": 98, "S": 57, "T": 53, "TT": 67, "TB": 31 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 180, "S": 93, "T": 53, "TT": 92, "TB": 88 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "3'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 2, "HEIGHT": "3'", "P": 48, "S": 40, "T": 38, "TT": 23, "TB": 25 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "3'", "P": 33, "S": 26, "T": 30, "TT": 15, "TB": 18 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "3'", "P": 57, "S": 46, "T": 42, "TT": 21, "TB": 36 },
    { "LETTER": "3", "WIDTH": 3, "HEIGHT": "3'", "P": 53, "S": 46, "T": 41, "TT": 25, "TB": 28 },
    { "LETTER": "4", "WIDTH": 3, "HEIGHT": "3'", "P": 55, "S": 38, "T": 36, "TT": 27, "TB": 28 },
    { "LETTER": "5", "WIDTH": 3, "HEIGHT": "3'", "P": 60, "S": 50, "T": 43, "TT": 31, "TB": 29 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "3'", "P": 65, "S": 47, "T": 41, "TT": 27, "TB": 38 },
    { "LETTER": "7", "WIDTH": 3, "HEIGHT": "3'", "P": 39, "S": 34, "T": 37, "TT": 25, "TB": 14 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "3'", "P": 78, "S": 44, "T": 36, "TT": 35, "TB": 43 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "3'", "P": 59, "S": 46, "T": 39, "TT": 31, "TB": 28 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "3'", "P": 25, "S": 25, "T": 26, "TT": 16, "TB": 9 },
    { "LETTER": "\\", "WIDTH": 2, "HEIGHT": "3'", "P": 26, "S": 23, "T": 24, "TT": 26, "TB": 0 },
    { "LETTER": "#", "WIDTH": 3.5, "HEIGHT": "3'", "P": 80, "S": 56, "T": 40, "TT": 39, "TB": 41 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "3'", "P": 75, "S": 44, "T": 36, "TT": 34, "TB": 41 },
    { "LETTER": "%", "WIDTH": 2, "HEIGHT": "3'", "P": 35, "S": 38, "T": 36, "TT": 17, "TB": 18 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 59, "S": 41, "T": 34, "TT": 21, "TB": 38 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 14, "T": 18, "TT": 14, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 16, "T": 19, "TT": 14, "TB": 0 },
    { "LETTER": "+", "WIDTH": 2, "HEIGHT": "3'", "P": 29, "S": 24, "T": 28, "TT": 12, "TB": 17 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "3'", "P": 14, "S": 14, "T": 18, "TT": 0, "TB": 14 },
    { "LETTER": "-", "WIDTH": 2, "HEIGHT": "3'", "P": 17, "S": 16, "T": 20, "TT": 6, "TB": 11 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "3'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 2, "HEIGHT": "3'", "P": 27, "S": 28, "T": 32, "TT": 13, "TB": 14 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "3'", "P": 24, "S": 21, "T": 22, "TT": 12, "TB": 12 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "3'", "P": 26, "S": 23, "T": 24, "TT": 12, "TB": 14 },
    { "LETTER": "=", "WIDTH": 2, "HEIGHT": "3'", "P": 34, "S": 25, "T": 24, "TT": 17, "TB": 17 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 31, "S": 33, "T": 30, "TT": 21, "TB": 10 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 77, "S": 47, "T": 36, "TT": 38, "TB": 39 },

    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "4'", "P": 186, "S": 73, "T": 65 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "4'", "P": 237, "S": 78, "T": 70 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "4'", "P": 186, "S": 81, "T": 84 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "4'", "P": 206, "S": 76, "T": 76 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "4'", "P": 215, "S": 83, "T": 80 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "4'", "P": 178, "S": 69, "T": 68 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "4'", "P": 220, "S": 90, "T": 79 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "4'", "P": 234, "S": 78, "T": 70 },
    { "LETTER": "I", "WIDTH": 3.5, "HEIGHT": "4'", "P": 141, "S": 56, "T": 60 },
    { "LETTER": "J", "WIDTH": 5, "HEIGHT": "4'", "P": 150, "S": 62, "T": 66 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "4'", "P": 207, "S": 91, "T": 75 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "4'", "P": 158, "S": 62, "T": 66 },
    { "LETTER": "M", "WIDTH": 6, "HEIGHT": "4'", "P": 281, "S": 115, "T": 88 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "4'", "P": 235, "S": 82, "T": 66 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "4'", "P": 198, "S": 76, "T": 76 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "4'", "P": 198, "S": 72, "T": 69 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "4'", "P": 217, "S": 80, "T": 70 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "4'", "P": 229, "S": 84, "T": 76 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "4'", "P": 225, "S": 90, "T": 72 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "4'", "P": 165, "S": 64, "T": 66 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "4'", "P": 194, "S": 74, "T": 78 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "4'", "P": 148, "S": 73, "T": 72 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "4'", "P": 239, "S": 106, "T": 87 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "4'", "P": 191, "S": 94, "T": 88 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "4'", "P": 149, "S": 63, "T": 63 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "4'", "P": 181, "S": 88, "T": 88 },
    { "LETTER": "A", "WIDTH": 5, "HEIGHT": "5'", "P": 276, "S": 94, "T": 82 },
    { "LETTER": "B", "WIDTH": 5, "HEIGHT": "5'", "P": 307, "S": 104, "T": 100 },
    { "LETTER": "C", "WIDTH": 5, "HEIGHT": "5'", "P": 242, "S": 98, "T": 101 },
    { "LETTER": "D", "WIDTH": 5, "HEIGHT": "5'", "P": 274, "S": 96, "T": 96 },
    { "LETTER": "E", "WIDTH": 5, "HEIGHT": "5'", "P": 280, "S": 106, "T": 106 },
    { "LETTER": "F", "WIDTH": 5, "HEIGHT": "5'", "P": 226, "S": 86, "T": 88 },
    { "LETTER": "G", "WIDTH": 5, "HEIGHT": "5'", "P": 292, "S": 110, "T": 107 },
    { "LETTER": "H", "WIDTH": 5, "HEIGHT": "5'", "P": 306, "S": 102, "T": 94 },
    { "LETTER": "I", "WIDTH": 4, "HEIGHT": "5'", "P": 182, "S": 70, "T": 74 },
    { "LETTER": "J", "WIDTH": 5, "HEIGHT": "5'", "P": 188, "S": 74, "T": 78 },
    { "LETTER": "K", "WIDTH": 5, "HEIGHT": "5'", "P": 270, "S": 109, "T": 95 },
    { "LETTER": "L", "WIDTH": 5, "HEIGHT": "5'", "P": 196, "S": 74, "T": 78 },
    { "LETTER": "M", "WIDTH": 7, "HEIGHT": "5'", "P": 396, "S": 138, "T": 109 },
    { "LETTER": "N", "WIDTH": 5, "HEIGHT": "5'", "P": 328, "S": 108, "T": 82 },
    { "LETTER": "O", "WIDTH": 5, "HEIGHT": "5'", "P": 256, "S": 96, "T": 96 },
    { "LETTER": "P", "WIDTH": 5, "HEIGHT": "5'", "P": 246, "S": 88, "T": 88 },
    { "LETTER": "Q", "WIDTH": 5, "HEIGHT": "5'", "P": 286, "S": 105, "T": 94 },
    { "LETTER": "R", "WIDTH": 5, "HEIGHT": "5'", "P": 296, "S": 108, "T": 105 },
    { "LETTER": "S", "WIDTH": 5, "HEIGHT": "5'", "P": 298, "S": 120, "T": 104 },
    { "LETTER": "T", "WIDTH": 5, "HEIGHT": "5'", "P": 210, "S": 78, "T": 82 },
    { "LETTER": "U", "WIDTH": 5, "HEIGHT": "5'", "P": 246, "S": 90, "T": 94 },
    { "LETTER": "V", "WIDTH": 5, "HEIGHT": "5'", "P": 187, "S": 89, "T": 88 },
    { "LETTER": "W", "WIDTH": 7, "HEIGHT": "5'", "P": 305, "S": 135, "T": 116 },
    { "LETTER": "X", "WIDTH": 5, "HEIGHT": "5'", "P": 230, "S": 116, "T": 108 },
    { "LETTER": "Y", "WIDTH": 5, "HEIGHT": "5'", "P": 197, "S": 83, "T": 78 },
    { "LETTER": "Z", "WIDTH": 5, "HEIGHT": "5'", "P": 258, "S": 110, "T": 110 },
    { "LETTER": "A", "WIDTH": 6, "HEIGHT": "6'", "P": 361, "S": 111, "T": 96, "TB": 223, "TT": 138 },
    { "LETTER": "B", "WIDTH": 6, "HEIGHT": "6'", "P": 466, "S": 114, "T": 109, "TB": 240, "TT": 226 },
    { "LETTER": "C", "WIDTH": 6, "HEIGHT": "6'", "P": 360, "S": 113, "T": 116, "TB": 170, "TT": 190 },
    { "LETTER": "D", "WIDTH": 6, "HEIGHT": "6'", "P": 406, "S": 108, "T": 108, "TB": 207, "TT": 199 },
    { "LETTER": "E", "WIDTH": 6, "HEIGHT": "6'", "P": 425, "S": 120, "T": 118, "TB": 218, "TT": 207 },
    { "LETTER": "F", "WIDTH": 6, "HEIGHT": "6'", "P": 344, "S": 98, "T": 99, "TB": 137, "TT": 207 },
    { "LETTER": "G", "WIDTH": 6, "HEIGHT": "6'", "P": 430, "S": 124, "T": 115, "TB": 240, "TT": 190 },
    { "LETTER": "H", "WIDTH": 6, "HEIGHT": "6'", "P": 459, "S": 114, "T": 104, "TB": 236, "TT": 223 },
    { "LETTER": "I", "WIDTH": 4, "HEIGHT": "6'", "P": 270, "S": 78, "T": 82, "TB": 137, "TT": 133 },
    { "LETTER": "J", "WIDTH": 6, "HEIGHT": "6'", "P": 287, "S": 84, "T": 88, "TB": 168, "TT": 119 },
    { "LETTER": "K", "WIDTH": 6, "HEIGHT": "6'", "P": 406, "S": 124, "T": 108, "TB": 216, "TT": 190 },
    { "LETTER": "L", "WIDTH": 6, "HEIGHT": "6'", "P": 295, "S": 84, "T": 88, "TB": 190, "TT": 105 },
    { "LETTER": "M", "WIDTH": 8, "HEIGHT": "6'", "P": 555, "S": 169, "T": 144, "TB": 295, "TT": 260 },
    { "LETTER": "N", "WIDTH": 6, "HEIGHT": "6'", "P": 445, "S": 134, "T": 112, "TB": 227, "TT": 218 },
    { "LETTER": "O", "WIDTH": 6, "HEIGHT": "6'", "P": 370, "S": 108, "T": 108, "TB": 190, "TT": 180 },
    { "LETTER": "P", "WIDTH": 6, "HEIGHT": "6'", "P": 375, "S": 98, "T": 98, "TB": 148, "TT": 227 },
    { "LETTER": "Q", "WIDTH": 6, "HEIGHT": "6'", "P": 446, "S": 126, "T": 109, "TB": 266, "TT": 180 },
    { "LETTER": "R", "WIDTH": 6, "HEIGHT": "6'", "P": 454, "S": 120, "T": 115, "TB": 227, "TT": 227 },
    { "LETTER": "S", "WIDTH": 6, "HEIGHT": "6'", "P": 443, "S": 134, "T": 118, "TB": 230, "TT": 213 },
    { "LETTER": "T", "WIDTH": 6, "HEIGHT": "6'", "P": 291, "S": 92, "T": 96, "TB": 91, "TT": 200 },
    { "LETTER": "U", "WIDTH": 6, "HEIGHT": "6'", "P": 379, "S": 104, "T": 108, "TB": 183, "TT": 196 },
    { "LETTER": "V", "WIDTH": 6, "HEIGHT": "6'", "P": 293, "S": 101, "T": 100, "TB": 113, "TT": 180 },
    { "LETTER": "W", "WIDTH": 8, "HEIGHT": "6'", "P": 476, "S": 152, "T": 131, "TB": 201, "TT": 275 },
    { "LETTER": "X", "WIDTH": 6, "HEIGHT": "6'", "P": 355, "S": 132, "T": 124, "TB": 179, "TT": 176 },
    { "LETTER": "Y", "WIDTH": 6, "HEIGHT": "6'", "P": 305, "S": 97, "T": 91, "TB": 117, "TT": 188 },
    { "LETTER": "Z", "WIDTH": 6, "HEIGHT": "6'", "P": 391, "S": 128, "T": 128, "TB": 198, "TT": 193 }

]

export const bebasKaiFontData = [
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 36, "T": 34, "TT": 24, "TB": 26 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "3'", "P": 25, "S": 22, "T": 26, "TT": 13, "TB": 12 },
    { "LETTER": "2", "WIDTH": 2.5, "HEIGHT": "3'", "P": 45, "S": 38, "T": 37, "TT": 21, "TB": 24 },
    { "LETTER": "3", "WIDTH": 2.5, "HEIGHT": "3'", "P": 47, "S": 37, "T": 35, "TT": 22, "TB": 25 },
    { "LETTER": "4", "WIDTH": 3, "HEIGHT": "3'", "P": 49, "S": 34, "T": 34, "TT": 27, "TB": 22 },
    { "LETTER": "5", "WIDTH": 2.5, "HEIGHT": "3'", "P": 47, "S": 39, "T": 36, "TT": 22, "TB": 25 },
    { "LETTER": "6", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 37, "T": 33, "TT": 21, "TB": 29 },
    { "LETTER": "7", "WIDTH": 2.5, "HEIGHT": "3'", "P": 34, "S": 30, "T": 32, "TT": 21, "TB": 13 },
    { "LETTER": "8", "WIDTH": 2.5, "HEIGHT": "3'", "P": 54, "S": 35, "T": 31, "TT": 25, "TB": 29 },
    { "LETTER": "9", "WIDTH": 2.5, "HEIGHT": "3'", "P": 50, "S": 37, "T": 33, "TT": 25, "TB": 25 },
    { "LETTER": "!", "WIDTH": 1, "HEIGHT": "3'", "P": 20, "S": 23, "T": 24, "TT": 11, "TB": 9 },
    { "LETTER": "\\", "WIDTH": 2.5, "HEIGHT": "3'", "P": 12, "S": 18, "T": 22, "TT": 12, "TB": 0 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "3'", "P": 82, "S": 72, "T": 55, "TT": 39, "TB": 43 },
    { "LETTER": "$", "WIDTH": 2.5, "HEIGHT": "3'", "P": 55, "S": 36, "T": 32, "TT": 25, "TB": 30 },
    { "LETTER": "%", "WIDTH": 2.5, "HEIGHT": "3'", "P": 35, "S": 38, "T": 36, "TT": 17, "TB": 18 },
    { "LETTER": "&", "WIDTH": 3, "HEIGHT": "3'", "P": 58, "S": 41, "T": 35, "TT": 20, "TB": 38 },
    { "LETTER": "'", "WIDTH": 1, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13, "TT": 5, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2, "HEIGHT": "3'", "P": 9, "S": 13, "T": 17, "TT": 9, "TB": 0 },
    { "LETTER": "+", "WIDTH": 2.5, "HEIGHT": "3'", "P": 29, "S": 24, "T": 28, "TT": 12, "TB": 17 },
    { "LETTER": ",", "WIDTH": 1, "HEIGHT": "3'", "P": 5, "S": 9, "T": 13, "TT": 0, "TB": 5 },
    { "LETTER": "-", "WIDTH": 2.5, "HEIGHT": "3'", "P": 17, "S": 16, "T": 20, "TT": 6, "TB": 11 },
    { "LETTER": ".", "WIDTH": 1, "HEIGHT": "3'", "P": 4, "S": 8, "T": 12, "TT": 0, "TB": 4 },
    { "LETTER": "/", "WIDTH": 2.5, "HEIGHT": "3'", "P": 27, "S": 28, "T": 32, "TT": 13, "TB": 14 },
    { "LETTER": ":", "WIDTH": 1, "HEIGHT": "3'", "P": 8, "S": 16, "T": 21, "TT": 4, "TB": 4 },
    { "LETTER": ";", "WIDTH": 1, "HEIGHT": "3'", "P": 9, "S": 17, "T": 22, "TT": 4, "TB": 5 },
    { "LETTER": "=", "WIDTH": 2.5, "HEIGHT": "3'", "P": 34, "S": 25, "T": 24, "TT": 17, "TB": 17 },
    { "LETTER": "?", "WIDTH": 2.5, "HEIGHT": "3'", "P": 29, "S": 32, "T": 30, "TT": 20, "TB": 9 },
    { "LETTER": "@", "WIDTH": 3, "HEIGHT": "3'", "P": 75, "S": 56, "T": 38, "TT": 37, "TB": 38 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "4'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 64, "T": 64, "TT": 52, "TB": 56 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "4'", "P": 47, "S": 30, "T": 34, "TT": 23, "TB": 24 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "4'", "P": 92, "S": 59, "T": 61, "TT": 40, "TB": 52 },
    { "LETTER": "3", "WIDTH": 3, "HEIGHT": "4'", "P": 106, "S": 74, "T": 71, "TT": 51, "TB": 55 },
    { "LETTER": "4", "WIDTH": 3.5, "HEIGHT": "4'", "P": 87, "S": 54, "T": 50, "TT": 40, "TB": 47 },
    { "LETTER": "5", "WIDTH": 3, "HEIGHT": "4'", "P": 115, "S": 71, "T": 67, "TT": 59, "TB": 56 },
    { "LETTER": "6", "WIDTH": 3, "HEIGHT": "4'", "P": 118, "S": 69, "T": 64, "TT": 57, "TB": 61 },
    { "LETTER": "7", "WIDTH": 3, "HEIGHT": "4'", "P": 86, "S": 50, "T": 53, "TT": 53, "TB": 33 },
    { "LETTER": "8", "WIDTH": 3, "HEIGHT": "4'", "P": 119, "S": 70, "T": 66, "TT": 55, "TB": 64 },
    { "LETTER": "9", "WIDTH": 3, "HEIGHT": "4'", "P": 117, "S": 71, "T": 64, "TT": 53, "TB": 63 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "4'", "P": 49, "S": 34, "T": 38, "TT": 27, "TB": 22 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "4'", "P": 26, "S": 26, "T": 30, "TT": 26, "TB": 0 },
    { "LETTER": "#", "WIDTH": 4, "HEIGHT": "4'", "P": 124, "S": 88, "T": 81, "TT": 63, "TB": 61 },
    { "LETTER": "$", "WIDTH": 3, "HEIGHT": "4'", "P": 97, "S": 60, "T": 48, "TT": 45, "TB": 52 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "4'", "P": 78, "S": 64, "T": 52, "TT": 38, "TB": 40 },
    { "LETTER": "&", "WIDTH": 4, "HEIGHT": "4'", "P": 117, "S": 77, "T": 63, "TT": 43, "TB": 74 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "4'", "P": 14, "S": 14, "T": 18, "TT": 14, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "4'", "P": 28, "S": 26, "T": 27, "TT": 28, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "4'", "P": 35, "S": 28, "T": 32, "TT": 14, "TB": 21 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "4'", "P": 14, "S": 14, "T": 18, "TT": 0, "TB": 14 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "4'", "P": 23, "S": 20, "T": 24, "TT": 8, "TB": 15 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "4'", "P": 12, "S": 12, "T": 16, "TT": 0, "TB": 12 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "4'", "P": 62, "S": 38, "T": 42, "TT": 30, "TB": 32 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "4'", "P": 24, "S": 24, "T": 28, "TT": 12, "TB": 12 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "4'", "P": 26, "S": 26, "T": 30, "TT": 7, "TB": 19 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "4'", "P": 46, "S": 31, "T": 28, "TT": 23, "TB": 23 },
    { "LETTER": "?", "WIDTH": 3, "HEIGHT": "4'", "P": 64, "S": 52, "T": 54, "TT": 44, "TB": 20 },
    { "LETTER": "@", "WIDTH": 4, "HEIGHT": "4'", "P": 149, "S": 102, "T": 71, "TT": 75, "TB": 74 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "5'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "5'", "P": 170, "S": 76, "T": 76, "TT": 88, "TB": 82 },
    { "LETTER": "1", "WIDTH": 2, "HEIGHT": "5'", "P": 81, "S": 40, "T": 44, "TT": 46, "TB": 35 },
    { "LETTER": "2", "WIDTH": 3, "HEIGHT": "5'", "P": 142, "S": 74, "T": 77, "TT": 75, "TB": 67 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "5'", "P": 158, "S": 82, "T": 82, "TT": 82, "TB": 76 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "5'", "P": 147, "S": 62, "T": 57, "TT": 71, "TB": 76 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "5'", "P": 179, "S": 84, "T": 83, "TT": 107, "TB": 77 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "5'", "P": 186, "S": 83, "T": 76, "TT": 102, "TB": 84 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "5'", "P": 133, "S": 68, "T": 72, "TT": 92, "TB": 41 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "5'", "P": 190, "S": 80, "T": 76, "TT": 99, "TB": 91 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "5'", "P": 186, "S": 83, "T": 76, "TT": 93, "TB": 93 },
    { "LETTER": "!", "WIDTH": 2, "HEIGHT": "5'", "P": 66, "S": 40, "T": 43, "TT": 38, "TB": 28 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "5'", "P": 34, "S": 28, "T": 31, "TT": 34, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "5'", "P": 248, "S": 112, "T": 104, "TT": 126, "TB": 122 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "5'", "P": 180, "S": 82, "T": 60, "TT": 94, "TB": 86 },
    { "LETTER": "%", "WIDTH": 3, "HEIGHT": "5'", "P": 127, "S": 100, "T": 92, "TT": 65, "TB": 62 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "5'", "P": 215, "S": 93, "T": 80, "TT": 93, "TB": 122 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "5'", "P": 19, "S": 16, "T": 20, "TT": 19, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "5'", "P": 33, "S": 26, "T": 27, "TT": 33, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "5'", "P": 63, "S": 32, "T": 36, "TT": 27, "TB": 36 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "5'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "5'", "P": 43, "S": 24, "T": 28, "TT": 17, "TB": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "5'", "P": 17, "S": 14, "T": 18, "TT": 0, "TB": 17 },
    { "LETTER": "/", "WIDTH": 3, "HEIGHT": "5'", "P": 87, "S": 52, "T": 56, "TT": 45, "TB": 42 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "5'", "P": 34, "S": 28, "T": 31, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "5'", "P": 36, "S": 30, "T": 33, "TT": 17, "TB": 19 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "5'", "P": 86, "S": 38, "T": 34, "TT": 43, "TB": 43 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "5'", "P": 109, "S": 66, "T": 69, "TT": 80, "TB": 29 },
    { "LETTER": "@", "WIDTH": 5, "HEIGHT": "5'", "P": 295, "S": 131, "T": 81, "TT": 154, "TB": 141 },
    { "LETTER": " ", "WIDTH": 2, "HEIGHT": "6'", "P": 0, "S": 0, "T": 0 },
    { "LETTER": "0", "WIDTH": 3.5, "HEIGHT": "6'", "P": 200, "S": 88, "T": 88, "TT": 98, "TB": 102 },
    { "LETTER": "1", "WIDTH": 2.5, "HEIGHT": "6'", "P": 96, "S": 46, "T": 50, "TT": 51, "TB": 45 },
    { "LETTER": "2", "WIDTH": 3.5, "HEIGHT": "6'", "P": 173, "S": 88, "T": 90, "TT": 86, "TB": 87 },
    { "LETTER": "3", "WIDTH": 3.5, "HEIGHT": "6'", "P": 193, "S": 95, "T": 98, "TT": 93, "TB": 100 },
    { "LETTER": "4", "WIDTH": 4, "HEIGHT": "6'", "P": 179, "S": 76, "T": 71, "TT": 80, "TB": 99 },
    { "LETTER": "5", "WIDTH": 3.5, "HEIGHT": "6'", "P": 199, "S": 92, "T": 96, "TT": 107, "TB": 92 },
    { "LETTER": "6", "WIDTH": 3.5, "HEIGHT": "6'", "P": 211, "S": 94, "T": 92, "TT": 107, "TB": 104 },
    { "LETTER": "7", "WIDTH": 4, "HEIGHT": "6'", "P": 146, "S": 80, "T": 82, "TT": 95, "TB": 51 },
    { "LETTER": "8", "WIDTH": 3.5, "HEIGHT": "6'", "P": 217, "S": 94, "T": 88, "TT": 105, "TB": 112 },
    { "LETTER": "9", "WIDTH": 3.5, "HEIGHT": "6'", "P": 211, "S": 94, "T": 92, "TT": 98, "TB": 113 },
    { "LETTER": "!", "WIDTH": 1.5, "HEIGHT": "6'", "P": 81, "S": 46, "T": 49, "TT": 43, "TB": 38 },
    { "LETTER": "\\", "WIDTH": 3, "HEIGHT": "6'", "P": 48, "S": 34, "T": 38, "TT": 48, "TB": 0 },
    { "LETTER": "#", "WIDTH": 5, "HEIGHT": "6'", "P": 280, "S": 128, "T": 20, "TT": 138, "TB": 142 },
    { "LETTER": "$", "WIDTH": 3.5, "HEIGHT": "6'", "P": 198, "S": 95, "T": 80, "TT": 96, "TB": 102 },
    { "LETTER": "%", "WIDTH": 4, "HEIGHT": "6'", "P": 150, "S": 102, "T": 110, "TT": 74, "TB": 76 },
    { "LETTER": "&", "WIDTH": 5, "HEIGHT": "6'", "P": 257, "S": 111, "T": 103, "TT": 101, "TB": 155 },
    { "LETTER": "'", "WIDTH": 2, "HEIGHT": "6'", "P": 22, "S": 17, "T": 21, "TT": 22, "TB": 0 },
    { "LETTER": "*", "WIDTH": 2.5, "HEIGHT": "6'", "P": 40, "S": 32, "T": 31, "TT": 40, "TB": 0 },
    { "LETTER": "+", "WIDTH": 3, "HEIGHT": "6'", "P": 73, "S": 36, "T": 40, "TT": 32, "TB": 41 },
    { "LETTER": ",", "WIDTH": 2, "HEIGHT": "6'", "P": 19, "S": 16, "T": 20, "TT": 0, "TB": 19 },
    { "LETTER": "-", "WIDTH": 3, "HEIGHT": "6'", "P": 43, "S": 24, "T": 28, "TT": 17, "TB": 26 },
    { "LETTER": ".", "WIDTH": 2, "HEIGHT": "6'", "P": 17, "S": 14, "T": 18, "TT": 0, "TB": 17 },
    { "LETTER": "/", "WIDTH": 4, "HEIGHT": "6'", "P": 98, "S": 62, "T": 66, "TT": 48, "TB": 50 },
    { "LETTER": ":", "WIDTH": 2, "HEIGHT": "6'", "P": 34, "S": 28, "T": 36, "TT": 17, "TB": 17 },
    { "LETTER": ";", "WIDTH": 2, "HEIGHT": "6'", "P": 41, "S": 33, "T": 40, "TT": 17, "TB": 24 },
    { "LETTER": "=", "WIDTH": 3, "HEIGHT": "6'", "P": 86, "S": 48, "T": 45, "TT": 43, "TB": 43 },
    { "LETTER": "?", "WIDTH": 3.5, "HEIGHT": "6'", "P": 130, "S": 72, "T": 75, "TT": 90, "TB": 40 },
    { "LETTER": "@", "WIDTH": 6, "HEIGHT": "6'", "P": 352, "S": 161, "T": 138, "TT": 175, "TB": 177 },
    { "LETTER": "A", "WIDTH": 4, "HEIGHT": "4'", "P": 126, "S": 59, "T": 55 },
    { "LETTER": "B", "WIDTH": 3, "HEIGHT": "4'", "P": 138, "S": 58, "T": 53 },
    { "LETTER": "C", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 58, "T": 60 },
    { "LETTER": "D", "WIDTH": 3, "HEIGHT": "4'", "P": 128, "S": 58, "T": 58 },
    { "LETTER": "E", "WIDTH": 3, "HEIGHT": "4'", "P": 110, "S": 56, "T": 60 },
    { "LETTER": "F", "WIDTH": 3, "HEIGHT": "4'", "P": 90, "S": 46, "T": 50 },
    { "LETTER": "G", "WIDTH": 3, "HEIGHT": "4'", "P": 121, "S": 64, "T": 48 },
    { "LETTER": "H", "WIDTH": 3, "HEIGHT": "4'", "P": 126, "S": 59, "T": 63 },
    { "LETTER": "I", "WIDTH": 2, "HEIGHT": "4'", "P": 58, "S": 30, "T": 34 },
    { "LETTER": "J", "WIDTH": 3, "HEIGHT": "4'", "P": 83, "S": 44, "T": 47 },
    { "LETTER": "K", "WIDTH": 3, "HEIGHT": "4'", "P": 117, "S": 61, "T": 57 },
    { "LETTER": "L", "WIDTH": 3, "HEIGHT": "4'", "P": 83, "S": 40, "T": 44 },
    { "LETTER": "M", "WIDTH": 3, "HEIGHT": "4'", "P": 137, "S": 57, "T": 56 },
    { "LETTER": "N", "WIDTH": 3, "HEIGHT": "4'", "P": 149, "S": 58, "T": 50 },
    { "LETTER": "O", "WIDTH": 3, "HEIGHT": "4'", "P": 120, "S": 56, "T": 54 },
    { "LETTER": "P", "WIDTH": 3, "HEIGHT": "4'", "P": 108, "S": 50, "T": 50 },
    { "LETTER": "Q", "WIDTH": 3, "HEIGHT": "4'", "P": 127, "S": 55, "T": 49 },
    { "LETTER": "R", "WIDTH": 3, "HEIGHT": "4'", "P": 130, "S": 60, "T": 59 },
    { "LETTER": "S", "WIDTH": 3, "HEIGHT": "4'", "P": 129, "S": 66, "T": 58 },
    { "LETTER": "T", "WIDTH": 3, "HEIGHT": "4'", "P": 88, "S": 42, "T": 46 },
    { "LETTER": "U", "WIDTH": 3, "HEIGHT": "4'", "P": 118, "S": 58, "T": 61 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "4'", "P": 114, "S": 60, "T": 61 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "4'", "P": 197, "S": 94, "T": 89 },
    { "LETTER": "X", "WIDTH": 3, "HEIGHT": "4'", "P": 119, "S": 60, "T": 58 },
    { "LETTER": "Y", "WIDTH": 3, "HEIGHT": "4'", "P": 85, "S": 48, "T": 49 },
    { "LETTER": "Z", "WIDTH": 3, "HEIGHT": "4'", "P": 105, "S": 58, "T": 62 },
    { "LETTER": "A", "WIDTH": 4, "HEIGHT": "5'", "P": 152, "S": 67, "T": 66 },
    { "LETTER": "B", "WIDTH": 3.5, "HEIGHT": "5'", "P": 197, "S": 80, "T": 76 },
    { "LETTER": "C", "WIDTH": 3.5, "HEIGHT": "5'", "P": 153, "S": 76, "T": 80 },
    { "LETTER": "D", "WIDTH": 3.5, "HEIGHT": "5'", "P": 180, "S": 76, "T": 76 },
    { "LETTER": "E", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "F", "WIDTH": 3.5, "HEIGHT": "5'", "P": 133, "S": 60, "T": 64 },
    { "LETTER": "G", "WIDTH": 3.5, "HEIGHT": "5'", "P": 173, "S": 84, "T": 79 },
    { "LETTER": "H", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 },
    { "LETTER": "I", "WIDTH": 2, "HEIGHT": "5'", "P": 73, "S": 36, "T": 40 },
    { "LETTER": "J", "WIDTH": 3.5, "HEIGHT": "5'", "P": 113, "S": 56, "T": 60 },
    { "LETTER": "K", "WIDTH": 3.5, "HEIGHT": "5'", "P": 161, "S": 80, "T": 79 },
    { "LETTER": "L", "WIDTH": 3.5, "HEIGHT": "5'", "P": 108, "S": 50, "T": 54 },
    { "LETTER": "M", "WIDTH": 3.5, "HEIGHT": "5'", "P": 213, "S": 77, "T": 68 },
    { "LETTER": "N", "WIDTH": 3.5, "HEIGHT": "5'", "P": 204, "S": 80, "T": 76 },
    { "LETTER": "O", "WIDTH": 3.5, "HEIGHT": "5'", "P": 170, "S": 76, "T": 76 },
    { "LETTER": "P", "WIDTH": 3.5, "HEIGHT": "5'", "P": 150, "S": 64, "T": 64 },
    { "LETTER": "Q", "WIDTH": 3.5, "HEIGHT": "5'", "P": 185, "S": 73, "T": 68 },
    { "LETTER": "R", "WIDTH": 3.5, "HEIGHT": "5'", "P": 183, "S": 80, "T": 79 },
    { "LETTER": "S", "WIDTH": 3.5, "HEIGHT": "5'", "P": 178, "S": 84, "T": 84 },
    { "LETTER": "T", "WIDTH": 4, "HEIGHT": "5'", "P": 113, "S": 52, "T": 56 },
    { "LETTER": "U", "WIDTH": 3.5, "HEIGHT": "5'", "P": 158, "S": 74, "T": 78 },
    { "LETTER": "V", "WIDTH": 4, "HEIGHT": "5'", "P": 137, "S": 71, "T": 72 },
    { "LETTER": "W", "WIDTH": 6, "HEIGHT": "5'", "P": 243, "S": 116, "T": 111 },
    { "LETTER": "X", "WIDTH": 3.5, "HEIGHT": "5'", "P": 154, "S": 82, "T": 78 },
    { "LETTER": "Y", "WIDTH": 3.5, "HEIGHT": "5'", "P": 110, "S": 63, "T": 66 },
    { "LETTER": "Z", "WIDTH": 3.5, "HEIGHT": "5'", "P": 168, "S": 74, "T": 78 }


]
