import React, { useRef, useState } from 'react'
import colorOptions from '../../common/options';
import { bebasKaiFontData, chunkFiveFontData, horseBackFontData, primeTimeFontData, standardFontData } from '../../common/fontCalculation';
import { mapCharacter } from '../../common/imageGeneration';
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select'


export default function Calculator() {

    const [data, setData] = useState()
    const [calculateView, setCalculateView] = useState(false)
    const [products, setProducts] = useState([])
    const [sleeveCount, setSleeveCount] = useState(null)
    const [message, setMessage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const contentToPrint = useRef(null);
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        setCalculateView(false)
        setLoader(false)
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectChange = (type, selectedOption) => {
        setLoader(false)
        setCalculateView(false);
        if (type === 'primaryColor' || type === 'outline1Color' || type === 'outline2Color') {
            setData(prevData => ({
                ...prevData,
                [type]: selectedOption.colorValue
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                [type]: selectedOption.value
            }));
        }
    };

    const handleFont = async (selectedOption) => {
        if (selectedOption.value === 'STANDARD') {
            await handleSelectChange('height', { label: "3ft", value: '3' });
        } else {
            await handleSelectChange('height', { label: "4ft", value: '4' });
        }
    }

    function getVariantId(colorName) {
        const colorVariantMap = {
            "FFFFFF": "47487775801625",
            "FFFF00": "47487785042201",
            "4CBB17": "47495040598297",
            "4169E1": "47495218528537",
            "000000": "47495235305753",
            "8B0000": "47496069480729",
            "FF5D8F": "47496089338137",
            "800080": "47495004291353",
            "808080": "47495022739737",
            "FFA500": "47495064551705",
            "006400": "47495100760345",
            "800000": "47495117570329",
            "A52A2A": "47495148536089",
            "FF0000": "47495195164953",
            "ADD8E6": "47495206699289",
            "000080": "47495225409817",
            "FFD700": "47495242940697",
            "C5B358": "47495251329305",
            "008080": "47495252902169",
            "DA9100": "47496072888601",
            "C0C0C0": "47496076853529",
            "964B00": "47496086389017",
        };

        return colorVariantMap[colorName] || null;
    }

    function getFontInfo(fontName) {
        const fontVariant = {
            "STANDARD": standardFontData,
            "BEBAS KAI": bebasKaiFontData,
            "CHUNK FIVE": chunkFiveFontData,
            "HORSEBACK": horseBackFontData,
            "PRIMETIME": primeTimeFontData
        };

        return fontVariant[fontName] || null;
    }

    function getLabelByValue(value) {
        const colorOption = colorOptions.find(option => option.value === value);
        return colorOption ? colorOption.label : null;
    }

    function calculateSumForString(inputString, height, font) {
        const letterSum = {};

        // Convert the string to uppercase to handle both upper and lower case letters
        const upperCaseString = inputString.toUpperCase();

        // Iterate through each character in the string
        for (let i = 0; i < upperCaseString.length; i++) {
            const letter = upperCaseString[i];

            // Check if the character is a letter and exists in the excelData
            const letterData = getFontInfo(font).find(item => item.LETTER === letter && item.HEIGHT === `${height}'`);
            if (letterData) {
                const { LETTER, P, S, T, WIDTH } = letterData;

                // Update the letterSum object
                if (!letterSum[LETTER]) {
                    letterSum[LETTER] = { P: 0, S: 0, T: 0, WIDTH: 0 };
                }

                letterSum[LETTER].P += P;
                letterSum[LETTER].S += S;
                letterSum[LETTER].T += T;
                letterSum[LETTER].WIDTH += WIDTH
            }
        }

        // Initialize counts
        let totalCountP = 0;
        let totalCountS = 0;
        let totalCountT = 0;
        let totalWidth = 0;

        // Iterate through each letter in the letterSum object
        for (const letter in letterSum) {
            if (letterSum.hasOwnProperty(letter)) {
                const letterData = letterSum[letter];

                // Accumulate counts
                totalCountP += letterData.P;
                totalCountS += letterData.S;
                totalCountT += letterData.T;
                totalWidth += letterData.WIDTH
            }
        }

        // console.log(totalCountP)
        // console.log(totalCountS)
        // console.log(totalCountT)
        // console.log(totalWidth)

        // console.log(totalCountP / 50)
        // console.log(totalCountS / 50)
        // console.log(totalCountT / 50)


        return {
            P: Math.ceil(totalCountP / 50),
            S: Math.ceil(totalCountS / 50),
            T: Math.ceil(totalCountT / 50),
            totalWidth: totalWidth
        };
    }

    const handleCalculate = (e) => {
        e.preventDefault()
        setMessage(null)
        if (data === undefined) {
            setMessage("Please Enter Text, Select Font and Height");
        } else {
            const { primaryColor, outline1Color, outline2Color, text, height, font } = data;
            if (!text || !height || !font || (primaryColor === undefined && outline1Color === undefined && outline2Color === undefined)) {
                setMessage("Please enter text, select font & height, select at least one color");
            }
            else {
                const result = calculateSumForString(text, height, font);
                setSleeveCount(result);

                const products = [];

                // Check if primary color exists
                if (primaryColor) {
                    // Define the first product object
                    const product1 = {
                        name: `${getLabelByValue(primaryColor)} Sleeve (50 Cups Per Sleeve) - Primary Color`,
                        quantity: result.P,
                        price: 11,
                        total: result.P * 11,
                        colors: primaryColor,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(primaryColor)}`
                        variantId: `${getVariantId(primaryColor)}`
                    };
                    products.push(product1);
                }

                // Check if outline1Color exists
                if (outline1Color !== 'None' && outline1Color !== undefined) {
                    // Define the second product object
                    const product2 = {
                        name: `${getLabelByValue(outline1Color)} Sleeve (50 Cups Per Sleeve) - Outline 1 Color`,
                        quantity: result.S,
                        price: 11,
                        total: result.S * 11,
                        colors: outline1Color,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(outline1Color)}`
                        variantId: `${getVariantId(outline1Color)}`
                    };

                    products.push(product2);

                }
                if (outline2Color !== 'None' && outline2Color !== undefined) {
                    // Define the second product object
                    const product2 = {
                        name: `${getLabelByValue(outline2Color)} Sleeve (50 Cups Per Sleeve) - Outline 2 Color`,
                        quantity: result.T,
                        price: 11,
                        total: result.T * 11,
                        colors: outline2Color,
                        // variantId: `gid://shopify/ProductVariant/${getVariantId(outline2Color)}`
                        variantId: `${getVariantId(outline2Color)}`
                    };

                    products.push(product2);

                }
                setCalculateView(true)
                setProducts(products)

                renderComposedImage(data?.text, data?.primaryColor, data?.outline1Color, data?.outline2Color, data?.font, data?.height, data?.height)
                const customerNote = `
                Text: ${data?.text.toUpperCase()}\n
                Font: ${data?.font}\n
                Height: ${data?.height}ft\n
                  Primary (Main): ${!data.primaryColor ? "n/a" : `${getLabelByValue(data?.primaryColor)} ${result?.P} Sleeves`}
        ${(!data.outline1Color || data.outline1Color === 'None') ?
                `Outline 1 (Middle): n/a` :
                `Outline 1 (Middle): ${getLabelByValue(data?.outline1Color)} ${result?.S} Sleeves`}
        ${(!data.outline2Color || data.outline2Color === 'None') ?
                `Outline 2 (Exterior): n/a` :
                `Outline 2 (Exterior): ${getLabelByValue(data?.outline2Color)} ${result?.T} Sleeves`}
                `

                let message = {
                    productValues: products.map((item) => {
                        return {
                            variant: item.variantId,
                            quantity: item.quantity
                        };
                    }),
                    customerNote: customerNote
                }

                window.parent.postMessage(message, "*");

            }
        }
    }

    const getTotal = () => {
        return products.reduce((accumulator, product) => accumulator + product.total, 0)
    }

    function renderComposedImage(text, primaryColor, outline1Color, outline2Color, tfont, tsizefolder, tsize) {
        setLoader(true)
        setImageUrl(null)
        let composedCanvas = document.createElement('canvas');
        let composedCtx = composedCanvas.getContext('2d');

        let imageSize = tsizefolder === "3" ? 55 : 65

        // Set the size of the composed canvas
        composedCanvas.width = tsizefolder + 10 * text.length; // Adjusting width for horizontal arrangement
        composedCanvas.height = tsizefolder + 10; // Assuming each character's image is 85x85

        let xOffset = 0; // Offset to position each character horizontally

        // Define a function to handle the rendering of each character
        function renderCharacter(ch, index) {
            if (ch === " ") {
                // If the character is a space, skip it and proceed to the next character
                xOffset += 25; // Increase xOffset for spacing
                if (index + 1 < text.length) {
                    renderCharacter(text[index + 1], index + 1);
                } else {
                    // All characters are rendered, return the data URL of the composed canvas
                    let composedDataURL = composedCanvas.toDataURL();
                    setImageUrl(composedDataURL); // Displaying the data URL in console for testing
                    setLoader(false)
                }
                return; // Exit the function
            }
            let source1 = new Image();
            source1.onload = function () {
                let canvas1 = document.createElement('canvas');
                let ctx1 = canvas1.getContext('2d');
                canvas1.width = imageSize;
                canvas1.height = imageSize;
                ctx1.drawImage(source1, 0, 0, imageSize, imageSize);

                let source2 = new Image();
                source2.onload = function () {
                    let canvas2 = document.createElement('canvas');
                    let ctx2 = canvas2.getContext('2d');
                    canvas2.width = imageSize;
                    canvas2.height = imageSize;
                    ctx2.drawImage(source2, 0, 0, imageSize, imageSize);

                    let source3 = new Image();
                    source3.onload = function () {
                        let canvas3 = document.createElement('canvas');
                        let ctx3 = canvas3.getContext('2d');
                        canvas3.width = imageSize;
                        canvas3.height = imageSize;
                        ctx3.drawImage(source3, 0, 0, imageSize, imageSize);

                        // Function to change pixel color
                        function changeColor(ctx, canvas, color) {
                            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            let data = imageData.data;

                            for (let i = 0; i < data.length; i += 4) {
                                // Check if the pixel is not fully transparent
                                if (data[i + 3] > 0) {
                                    data[i] = color[0]; // Red
                                    data[i + 1] = color[1]; // Green
                                    data[i + 2] = color[2]; // Blue
                                }
                            }
                            ctx.putImageData(imageData, 0, 0);
                        }

                        if (primaryColor)
                            changeColor(ctx1, canvas1, hexToRgb(primaryColor));
                        if (outline1Color !== 'None' && outline1Color !== undefined)
                            changeColor(ctx2, canvas2, hexToRgb(outline1Color));
                        if (outline2Color !== 'None' && outline2Color !== undefined)
                            changeColor(ctx3, canvas3, hexToRgb(outline2Color));

                        // Draw the composed image to the composed canvas horizontally
                        composedCtx.drawImage(canvas1, xOffset, 0);
                        if (outline1Color !== 'None' && outline1Color !== undefined)
                            composedCtx.drawImage(canvas2, xOffset, 0); // Adjusting x offset for each image
                        if (outline2Color !== 'None' && outline2Color !== undefined)
                            composedCtx.drawImage(canvas3, xOffset, 0); // Adjusting x offset for each image

                        // Increase xOffset for the next character
                        xOffset += imageSize - 10; // Adjusting x offset for each character

                        // Check if there are more characters to render
                        if (index + 1 < text.length) {
                            // Render the next character
                            renderCharacter(text[index + 1], index + 1);
                        } else {
                            // All characters are rendered, return the data URL of the composed canvas
                            let composedDataURL = composedCanvas.toDataURL();
                            setImageUrl(composedDataURL); // Displaying the data URL in console for testing
                            setLoader(false)
                        }
                    };

                    source3.src = "./images/utils/Images/" + tfont + "/Export PNG/" + tsizefolder + "'/" + tfont + "_" + tsize + "'_" + mapCharacter(ch) + "(T).png";
                };

                source2.src = "./images/utils/Images/" + tfont + "/Export PNG/" + tsizefolder + "'/" + tfont + "_" + tsize + "'_" + mapCharacter(ch) + "(S).png";
            };

            source1.src = "./images/utils/Images/" + tfont + "/Export PNG/" + tsizefolder + "'/" + tfont + "_" + tsize + "'_" + mapCharacter(ch) + "(P).png";
        }

        // Start rendering the first character
        if (text.length > 0) {
            renderCharacter(text[0], 0);
        }
    }

    function hexToRgb(hex) {
        if (hex === undefined || hex === null) {
            return "rgba(0, 0, 0, 0);" // Transparent color
        }

        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
            0 // Alpha value set to 0 for full transparency
        ] : null;
    }


    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    const getExpectedImage = (colorValue) => {
        const selectedColor = colorOptions.find(option => option.value === colorValue);
        return selectedColor ? selectedColor.image : null;

    }

    const addToCartAndRedirect = () => {

        const customerNote = `
        Text: ${data?.text}\n
        Font: ${data?.font}\n
        Height: ${data?.height}ft\n
        Length: ${sleeveCount?.totalWidth}ft\n
        Primary (Main): ${!data.primaryColor ? "n/a" : `${getLabelByValue(data?.primaryColor)} ${sleeveCount?.P} Sleeves`}
        ${(!data.outline1Color || data.outline1Color === 'None') ?
                `Outline 1 (Middle): n/a` :
                `Outline 1 (Middle): ${getLabelByValue(data?.outline1Color)} ${sleeveCount?.S} Sleeves`}
        ${(!data.outline2Color || data.outline2Color === 'None') ?
                `Outline 2 (Exterior): n/a` :
                `Outline 2 (Exterior): ${getLabelByValue(data?.outline2Color)} ${sleeveCount?.T} Sleeves`}
    `;

        let message = {
            productValues: products.map((item) => {
                return {
                    variant: item.variantId,
                    quantity: item.quantity,
                    note: item.variantId
                };
            }),
            customerNote: customerNote
        }

        console.log(message)

        window.parent.postMessage(message, "*");
    }

    return (
        <div ref={contentToPrint}>
            <div className='container mt-2 mb-2' >
                <h3 className='mb-3'>Font Calculator</h3>
                <div className='calc-container' >
                    <form onSubmit={(e) => handleCalculate(e)}>
                        <div className='form-group p-4'>
                            <div className='row col-lg-12 col-md-12'>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="text" className="form-label">Enter text</label>
                                    <input type="text" name="text" onChange={(e) => handleChange(e)} className="form-control" id="text" required />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="font" className="form-label">Select Font</label>
                                    <Select
                                        options={[
                                            { value: "STANDARD", label: "STANDARD" },
                                            { value: "BEBAS KAI", label: "BEBAS KAI" },
                                            { value: "CHUNK FIVE", label: "CHUNK FIVE" },
                                            { value: "HORSEBACK", label: "HORSEBACK" },
                                            { value: "PRIMETIME", label: "PRIMETIME" }
                                        ]}
                                        onChange={(value) => {
                                            handleSelectChange("font", value);
                                            handleFont(value);
                                        }}
                                        placeholder=""
                                    />

                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <label htmlFor="height" className="form-label">Select Height</label>
                                    <Select
                                        options={data?.font === 'STANDARD' ? [
                                            { value: "3", label: "3ft" },
                                        ] : [
                                            { value: "4", label: "4ft" },
                                            { value: "5", label: "5ft" },
                                        ]}
                                        onChange={(value) => handleSelectChange("height", value)}
                                        value={{ label: data?.height !== undefined ? `${data?.height}ft` : "" }}
                                        placeholder="Select Height"
                                    />

                                </div>
                            </div>
                            <div className='row col-lg-12 col-md-12 mt-1'>
                                <label>Select Color:</label>
                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="p-color"
                                        options={colorOptions?.filter(x => {
                                            return x.value !== 'None'
                                        }).map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("primaryColor", value)}
                                        value={{ label: <label>Primary(Main){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.primaryColor)} /> </label> }}

                                    />
                                </div>
                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="o1-color"
                                        options={colorOptions?.map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("outline1Color", value)}
                                        value={{ label: data?.outline1Color === 'None' ? data?.outline1Color : <label>Outline 1(Middle){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.outline1Color)} /> </label> }}

                                    />
                                </div>

                                <div className='col-lg-4 col-md-4'>
                                    <Select
                                        id="o2-color"
                                        options={colorOptions?.map((item) => {
                                            return { value: `${item.value} -${item.label}`, colorValue: item.value, label: <label>{item.label}{" "}<img style={{ width: '50px' }} alt="" src={item.image} /> </label> }
                                        })}
                                        onChange={(value) => handleSelectChange("outline2Color", value)}
                                        value={{ label: data?.outline2Color === 'None' ? data?.outline2Color : <label>Outline 2(Exterior){" "}<img style={{ width: '25px' }} alt="" src={getExpectedImage(data?.outline2Color)} /> </label> }}
                                    />
                                </div>
                            </div>
                            {
                                message !== null &&
                                <label className='error-text mt-2'>{message}</label>
                            }
                            <div className='mt-4'>
                                <button onClick={() => {

                                }} type="submit" className='btn btn-secondary'>{loader ? <img alt="" style={{ width: '40px' }} src={"/images/small_loader.gif"} /> : "Calculate"}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    calculateView && loader === false &&
                    <>
                        <div className='mt-3 char-generator'>
                            <div>
                                <div style={{ marginLeft: '6%' }}>
                                    <img src={imageUrl} alt="" style={{ marginTop: '30px' }} />
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label><b>Height:</b> {data?.height}ft , <b>Length:</b> {sleeveCount?.totalWidth}ft</label><br />
                            <label><b>Primary (Main): </b>{!data.primaryColor ? "n/a" : `${sleeveCount?.P} Sleeves =$${sleeveCount.P * 11} USD`} </label><br />
                            {
                                data.outline1Color !== 'None' && data.outline1Color !== undefined &&
                                <>
                                    <label><b>Outline 1 (Middle): </b>{!data.outline1Color ? "n/a" : `${sleeveCount?.S} Sleeves =$${sleeveCount.S * 11} USD`}</label><br />
                                </>
                            }
                            {
                                data.outline2Color !== 'None' && data.outline2Color !== undefined &&
                                <>
                                    <label><b>Outline 2 (Exterior): </b>{!data.outline2Color ? "n/a" : `${sleeveCount?.T} Sleeves = $${sleeveCount.T * 11} USD`}</label><br />
                                </>
                            }
                            <label><b>Total Cost: </b>${getTotal()} USD</label><br />
                        </div>
                        <div className='mt-4 mb-4 calc-container'>
                            <div className='container p-1'>
                                <div className='m-4'>
                                    <div className='mt-2 mb-2'>
                                        <button onClick={() => {
                                            handlePrint(null, () => contentToPrint.current)
                                        }
                                        } type="button" className='btn btn-secondary'>Print <i className="bi bi-printer"></i></button>
                                    </div>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Items</th>
                                                <th>QTY</th>
                                                <th>PRICE</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                products.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td><img style={{ width: '50px' }} alt="" src={getExpectedImage(item?.colors)} />{" "}{item?.name}</td>
                                                        <td>{item?.quantity}</td>
                                                        <td>${item?.price} USD</td>
                                                        <td>${item?.total} USD</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <label>Preview</label>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6'>
                                            <div className='mt-3 char-generator'>
                                                <div>
                                                    <div style={{ marginLeft: '6%' }}>
                                                        <img src={imageUrl} alt="" style={{ marginTop: '30px', height: '130px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 mt-3'>
                                            <label><b>Text:</b> {data?.text.toUpperCase()}</label><br />
                                            <label><b>Font:</b> {data?.font}</label><br />
                                            <label><b>Height:</b> {data?.height}ft<br /><b>Length:</b> {sleeveCount?.totalWidth}ft</label>
                                        </div>
                                        <div className='col-lg-3 col-md-3 mt-3'>
                                            <label>TOTAL: ${getTotal()} USD</label>
                                            <br />
                                            <label style={{ fontSize: '10px' }}>No Taxes Are Charged. Shipping is Calculated at checkout</label>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}