const colorOptions = [
    { value: "None", image: "", label: "None" },
    { value: "FFFFFF", image: "/images/utils/Images/CUPSIMG/WHITE 101.png", label: "White" },
    { value: "FFFF00", image: "/images/utils/Images/CUPSIMG/YELLOW 102.png", label: "Yellow" },
    { value: "4CBB17", image: "/images/utils/Images/CUPSIMG/KELLY 105.png", label: "Kelly Green" },
    { value: "4169E1", image: "/images/utils/Images/CUPSIMG/ROYAL BLUE 113.png", label: "Royal Blue" },
    { value: "000000", image: "/images/utils/Images/CUPSIMG/BLACK 115.png", label: "Black" },
    { value: "8B0000", image: "/images/utils/Images/CUPSIMG/DARK RED 119.png", label: "Dark Red" },
    { value: "FF5D8F", image: "/images/utils/Images/CUPSIMG/PUNCH.png", label: "Punch" },
    { value: "800080", image: "/images/utils/Images/CUPSIMG/PURPLE 103.png", label: "Purple" },
    { value: "808080", image: "/images/utils/Images/CUPSIMG/GRAY 104.png", label: "Grey" },
    { value: "FFA500", image: "/images/utils/Images/CUPSIMG/ORANGE 106.png", label: "Orange" },
    { value: "006400", image: "/images/utils/Images/CUPSIMG/DARK GREEN 107.png", label: "Dark Green" },
    { value: "800000", image: "/images/utils/Images/CUPSIMG/MAROON 108.png", label: "Maroon" },
    { value: "A52A2A", image: "/images/utils/Images/CUPSIMG/BROWN 110.png", label: "Brown" },
    { value: "FF0000", image: "/images/utils/Images/CUPSIMG/RED 111.png", label: "Red" },
    { value: "ADD8E6", image: "/images/utils/Images/CUPSIMG/LIGHT BLUE 112.png", label: "Light Blue" },
    { value: "000080", image: "/images/utils/Images/CUPSIMG/NAVY BLUE 114.png", label: "Navy Blue" },
    { value: "FFD700", image: "/images/utils/Images/CUPSIMG/YELLOW GOLD 116.png", label: "Yellow Gold" },
    { value: "C5B358", image: "/images/utils/Images/CUPSIMG/VEGAS GOLD 117.png", label: "Vegas Gold" },
    { value: "008080", image: "/images/utils/Images/CUPSIMG/TEAL 118.png", label: "Teal" },
    { value: "DA9100", image: "/images/utils/Images/CUPSIMG/HARVEST GOLD 120.png", label: "Harvest Gold" },
    { value: "C0C0C0", image: "/images/utils/Images/CUPSIMG/SILVER 121.png", label: "Silver" },
    { value: "964B00", image: "/images/utils/Images/CUPSIMG/CARDBOARD 122.png", label: "Cardboard" },
];

export default colorOptions;
