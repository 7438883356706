import './App.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Calculator from './Screens/Calculator';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Calculator />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
